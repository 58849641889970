import React from 'react'

export default function CountryChile({ width = 32, height = 32 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_163_1515)'>
        <mask
          style={{ maskType: 'luminance' }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width={32}
          height={32}
        >
          <path
            d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_163_1515)'>
          <path d='M0 16L15.9062 12.7937L32 16V32H0V16Z' fill='#D80027' />
          <path d='M0 0H16L19.2938 8.3L16 16H0V0Z' fill='#0052B4' />
          <path
            d='M16 0H32V16H16V0ZM9.525 5.5625L10.5625 8.75H13.9125L11.2 10.725L12.2375 13.9125L9.525 11.9438L6.8125 13.9125L7.85 10.725L5.1375 8.75H8.4875L9.525 5.5625Z'
            fill='white'
          />
        </g>
      </g>
      <defs>
        <clipPath>
          <rect width={32} height={32} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
