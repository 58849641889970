import React from 'react'

export default function UsersThree({ width = 22, height = 23, color = 'currentColor' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.2204 15.804C13.0633 15.804 14.5573 14.2944 14.5573 12.4323C14.5573 10.5701 13.0633 9.06055 11.2204 9.06055C9.37751 9.06055 7.88354 10.5701 7.88354 12.4323C7.88354 14.2944 9.37751 15.804 11.2204 15.804Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.8931 10.4082C17.6703 10.4069 18.4372 10.5891 19.1324 10.9404C19.8276 11.2916 20.432 11.8021 20.8973 12.4312'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.54346 12.4312C2.00878 11.8021 2.61316 11.2916 3.30837 10.9404C4.00359 10.5891 4.77042 10.4069 5.54769 10.4082'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.41528 18.839C6.85477 17.9296 7.53821 17.1631 8.38774 16.627C9.23728 16.0909 10.2188 15.8066 11.2204 15.8066C12.222 15.8066 13.2035 16.0909 14.053 16.627C14.9025 17.1631 15.586 17.9296 16.0255 18.839'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.54769 10.4084C5.04102 10.4089 4.54467 10.2637 4.11663 9.98981C3.68859 9.71588 3.34654 9.32452 3.13045 8.86145C2.91436 8.39839 2.83315 7.88275 2.89631 7.37479C2.95947 6.86682 3.1644 6.38751 3.48714 5.99285C3.80988 5.5982 4.23711 5.3045 4.7189 5.14607C5.2007 4.98765 5.71717 4.97104 6.20797 5.09818C6.69876 5.22533 7.14361 5.49099 7.49053 5.86411C7.83746 6.23723 8.07213 6.7024 8.16713 7.20528'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.2737 7.20528C14.3687 6.7024 14.6034 6.23723 14.9503 5.86411C15.2972 5.49099 15.742 5.22533 16.2328 5.09818C16.7236 4.97104 17.2401 4.98765 17.7219 5.14607C18.2037 5.3045 18.6309 5.5982 18.9537 5.99285C19.2764 6.38751 19.4813 6.86682 19.5445 7.37479C19.6077 7.88275 19.5264 8.39839 19.3104 8.86145C19.0943 9.32452 18.7522 9.71588 18.3242 9.98981C17.8961 10.2637 17.3998 10.4089 16.8931 10.4084'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
