import React from 'react'

export default function PaperPlaneTilt({ width = 31, height = 31 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 31 30'
      fill='none'
    >
      <path
        d='M25.1445 4.20702L3.30079 10.3594C3.11485 10.4102 2.94909 10.5171 2.82611 10.6656C2.70313 10.814 2.62889 10.9968 2.61352 11.1889C2.59815 11.3811 2.64238 11.5733 2.7402 11.7394C2.83802 11.9055 2.98467 12.0374 3.16017 12.1172L13.1914 16.8633C13.3879 16.9543 13.5457 17.1121 13.6367 17.3086L18.3828 27.3398C18.4626 27.5153 18.5945 27.662 18.7606 27.7598C18.9267 27.8576 19.1189 27.9019 19.3111 27.8865C19.5032 27.8711 19.686 27.7969 19.8344 27.6739C19.9829 27.5509 20.0898 27.3852 20.1406 27.1992L26.293 5.35546C26.3396 5.19606 26.3425 5.02705 26.3013 4.86615C26.2602 4.70526 26.1765 4.55839 26.059 4.44095C25.9416 4.32352 25.7948 4.23984 25.6339 4.19868C25.473 4.15752 25.3039 4.1604 25.1445 4.20702V4.20702Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.4961 17.0039L18.793 11.707'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
