// @ts-nocheck
import React from 'react'
import Select from './Select'
import FlagIcon from './FlagIcon'
import selectOptions from '@howdy/core/constants/selectOptions'

export default function CountrySelect(props) {
  return (
    <Select
      name='countries'
      options={props.countries || selectOptions.countries}
      placeholder={
        <div className='flex items-center gap-1'>
          <div className='h-[32px] w-[32px]'>
            <FlagIcon country={'Placeholder'} />
          </div>
          <p>Select Country</p>
        </div>
      }
      {...props}
    />
  )
}
