const EyeSlash = ({ width = 33, height = 32, color = 'currentColor' }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 17 16'
    fill='none'
  >
    <path d='M3.5 2.5L13.5 13.5' stroke={color} stroke-linecap='round' stroke-linejoin='round' />
    <path
      d='M10.181 9.85002C9.72219 10.2703 9.12193 10.5024 8.49973 10.5C7.99515 10.5 7.50239 10.3472 7.08624 10.0619C6.67008 9.77654 6.35001 9.37194 6.16811 8.90129C5.9862 8.43063 5.95097 7.91595 6.06705 7.4249C6.18313 6.93384 6.44509 6.48941 6.81848 6.15002'
      stroke={color}
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M5.125 4.28748C2.575 5.57498 1.5 7.99998 1.5 7.99998C1.5 7.99998 3.5 12.5 8.5 12.5C9.67161 12.5095 10.8286 12.2396 11.875 11.7125'
      stroke={color}
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M13.5373 10.5688C14.8998 9.35002 15.4998 8.00002 15.4998 8.00002C15.4998 8.00002 13.4998 3.50002 8.4998 3.50002C8.06634 3.49916 7.63357 3.5347 7.20605 3.60627'
      stroke={color}
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M8.96875 5.5437C9.5004 5.64444 9.98487 5.91537 10.349 6.31561C10.7132 6.71584 10.9373 7.22367 10.9875 7.76245'
      stroke={color}
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
)

export default EyeSlash
