import React from 'react'

export default function CountryEcuador({ width = 32, height = 32 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_163_1531)'>
        <mask
          style={{ maskType: 'luminance' }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width={32}
          height={32}
        >
          <path
            d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_163_1531)'>
          <path d='M0 24L15.9187 21.9562L32 23.9937V32H0V24Z' fill='#D80027' />
          <path d='M0 16L15.9375 14.3125L32 16V24H0V16Z' fill='#0052B4' />
          <path d='M0 0H32V16H0V0Z' fill='#FFDA44' />
          <path
            d='M16 21.5625C19.0721 21.5625 21.5625 19.0721 21.5625 16C21.5625 12.9279 19.0721 10.4375 16 10.4375C12.9279 10.4375 10.4375 12.9279 10.4375 16C10.4375 19.0721 12.9279 21.5625 16 21.5625Z'
            fill='#FFDA44'
          />
          <path
            d='M16 19.475C14.0812 19.475 12.5188 17.9125 12.5188 16V13.9125C12.5188 12.9892 12.8855 12.1037 13.5384 11.4509C14.1912 10.798 15.0767 10.4313 16 10.4313C16.9233 10.4313 17.8088 10.798 18.4616 11.4509C19.1145 12.1037 19.4813 12.9892 19.4813 13.9125V16C19.4813 17.9125 17.9187 19.475 16 19.475Z'
            fill='#338AF3'
          />
          <path
            d='M21.5625 7.65H17.3937C17.3937 7.28035 17.2469 6.92585 16.9855 6.66447C16.7242 6.40309 16.3696 6.25625 16 6.25625C15.6304 6.25625 15.2758 6.40309 15.0145 6.66447C14.7531 6.92585 14.6062 7.28035 14.6062 7.65H10.4375C10.4489 8.02361 10.6053 8.37811 10.8737 8.6383C11.1421 8.89849 11.5012 9.04392 11.875 9.04375H11.825C11.825 9.8125 12.45 10.4375 13.2188 10.4375C13.2188 11.2062 13.8437 11.825 14.6062 11.825H17.3937C18.1625 11.825 18.7812 11.2 18.7812 10.4375C19.55 10.4375 20.175 9.8125 20.175 9.04375H20.125C20.4988 9.04392 20.8579 8.89849 21.1263 8.6383C21.3947 8.37811 21.5511 8.02361 21.5625 7.65Z'
            fill='#333333'
          />
        </g>
      </g>
      <defs>
        <clipPath>
          <rect width={32} height={32} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
