import React, { FC } from 'react'
import { Props } from 'react-modal'
import Base from '@howdy/core/elements/Modal/Base'
import Button from '@howdy/core/elements/Button'
import Circle from '@howdy/core/elements/Circle'
import Lock from '@howdy/core/elements/Icons/Lock'
import { useUser } from 'contexts/userContext'

interface RequestAccountAccessModalProps extends Props {
  onRequestOk: () => void
}

const RequestAccountAccessModal: FC<RequestAccountAccessModalProps> = ({
  isOpen,
  onRequestOk,
  onRequestClose,
}) => {
  const { userState } = useUser()

  const isPending = userState?.data?.pendingAccountAccessRequest

  return (
    <Base isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className='flex flex-col items-center md:w-[500px]'>
        <Circle>
          <Lock />
        </Circle>
        <h1 className='mb-4 mt-4 text-center text-xl font-bold text-[#1B1B1B]'>
          {isPending
            ? 'You have already requested access to this page'
            : 'You need permission to access this page'}
        </h1>
        <p className='mb-4 text-center'>
          {!isPending ? (
            'We already have an account with your domain. To access other areas it is necessary to request permission to the account owner.'
          ) : (
            <>
              You will be notified by email when the owner reviews your request.
              <br />
              Once the request is approved, you must login again.
            </>
          )}
        </p>
        <div className='flex w-full flex-col gap-2 md:w-auto md:flex-row'>
          {!isPending && (
            <div className='order-1 md:order-2'>
              <Button onClick={onRequestOk} className='w-full md:w-auto'>
                Request access
              </Button>
            </div>
          )}
          <div className='order-2 md:order-1'>
            <Button
              style={isPending ? 'primary' : 'secondary'}
              onClick={onRequestClose}
              className='w-full md:w-auto'
            >
              {isPending ? 'Close' : 'Cancel'}
            </Button>
          </div>
        </div>
      </div>
    </Base>
  )
}

export default RequestAccountAccessModal
