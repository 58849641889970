import React from 'react'
import { motion } from 'framer-motion'
import Check from '@howdy/core/elements/Icons/Check'
import cn from 'classnames'

const Step = ({ title, icon, isSelected, color = 'howdy-purple' }) => {
  const bgColor = 'bg-' + color
  const borderColor = 'border-' + color
  const clas = `${bgColor} border-1 ${borderColor}`
  return (
    <div className={cn('ml-[-34.5px]', '[&>*]: transition-all duration-300 ease-out')}>
      <div className={cn('flex flex-row items-center gap-4')}>
        <div className={cn('relative')}>
          <div
            className={cn(
              `${
                isSelected ? bgColor : 'bg-[#C1C1C1]'
              } flex h-[65px] w-[65px] items-center justify-center rounded-full text-black transition-colors`,
            )}
          >
            {icon ? icon : <Check />}
          </div>
        </div>
        <span className={cn('max-w-[180px] font-worksans text-sm')}>{title}</span>
      </div>
    </div>
  )
}

export default Step
