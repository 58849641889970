import { Controller } from 'react-hook-form'
import SelectInput from '@howdy/core/elements/Select'
import { userRoles } from './userRoles'

export function DropdownRole({ control, errors }) {
  return (
    <div className='relative w-full'>
      <Controller
        control={control}
        name='role'
        rules={{ required: true }}
        render={({ field }) => {
          const value = userRoles.find((c) => c.value === field.value)
          return (
            <SelectInput
              isDisabled={field.value == 'Owner'}
              placeholder='Role'
              value={value}
              label='Role'
              error={errors['role']?.message}
              options={userRoles.filter((role) => role.value != 'Owner')}
              input={{
                ...field,
              }}
              required={true}
            />
          )
        }}
      />
    </div>
  )
}
