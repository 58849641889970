import Circle from '@howdy/core/elements/Circle'
import CreditCard from '@howdy/core/elements/Icons/CreditCard'
import Info from '@howdy/core/elements/Icons/Info'

export default function Disclaimers({ invite }) {
  return (
    <div className='flex w-full flex-col'>
      <div className='mt-3 flex flex-col items-center gap-3 rounded-lg bg-[#227] px-5 py-4 text-sm text-white md:flex-row'>
        <div className='h-[55px] w-[55px]'>
          <Circle bgColor='white' borderColor='white' className='text-[#227]'>
            <Info color='#227' />
          </Circle>
        </div>
        <p>
          We won’t charge anything until your engineers complete their first week on the job. You
          can change your information whenever you need.
        </p>
      </div>
      <div className='mt-3 flex flex-col items-center gap-3 rounded-lg bg-[#FFB133] px-5 py-4 text-sm text-howdy-black md:flex-row'>
        <Circle bgColor='white' borderColor='white' className='text-[#FFB133]'>
          <CreditCard color='#FFB133' />
        </Circle>
        <p>
          Invite someone to fill payment info{' '}
          <span className='cursor-pointer underline' onClick={invite}>
            here
          </span>
        </p>
      </div>
    </div>
  )
}
