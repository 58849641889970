import React, { ReactNode } from 'react'
import clxs from 'clsx'

const HOVER_COLORS = {
  'howdy-light-green': 'hover:bg-howdy-light-green group-hover:bg-howdy-light-green',
}

const ACTIVE_COLORS = {
  'howdy-primary-green': 'active:bg-howdy-primary-green group-active:bg-howdy-primary-green',
}

export default function RoundIcon({
  children,
  iconColor = 'text-black',
  color = 'bg-howdy-purple',
  hoverColor = 'howdy-light-green',
  activeColor = 'howdy-primary-green',
  size = 55,
  interactive = true,
}: {
  children: ReactNode
  iconColor?: string
  color?: string
  hoverColor?: string
  activeColor?: string
  backColor?: string
  size?: number
  interactive?: boolean
}) {
  const containerSize = size + 3
  const containerSizeStrings = `w-[${containerSize}px] h-[${containerSize}px]`
  return (
    <div className={`relative ${containerSizeStrings}`}>
      <div
        className={clxs(
          `w-[${size}px] h-[${size}px]`,
          'relative flex items-center justify-center rounded-full',
          color,
          iconColor,
          { [HOVER_COLORS[hoverColor as keyof typeof HOVER_COLORS]]: interactive },
          { [ACTIVE_COLORS[activeColor as keyof typeof ACTIVE_COLORS]]: interactive },
        )}
      >
        <div>{children}</div>
      </div>
    </div>
  )
}
