import { FC } from 'react'
import { useRouter } from 'next/router'
import { Props } from 'react-modal'
import { useUser } from 'contexts/userContext'
import Base from '@howdy/core/elements/Modal/Base'
import Button from '@howdy/core/elements/Button'

interface RestrictAccessModalProps extends Props {
  onRequestOk: () => void
  flow: string
  redirectUrl: string
}

const RestrictAccessModal: FC<RestrictAccessModalProps> = ({
  isOpen,
  flow,
  redirectUrl,
  onRequestOk,
  onRequestClose,
}) => {
  const { userState } = useUser()
  const router = useRouter()

  return (
    <Base isOpen={isOpen} onRequestClose={onRequestClose}>
      <>
        <h1 className='mb-4 mt-4 text-center text-3xl font-bold text-[#1B1B1B]'>Howdy!</h1>
        <p className='mb-4 text-center'>
          To access other areas it is necessary to{' '}
          {flow == 'Login' ? 'access with your account.' : 'create an account.'}
        </p>
        <div className='flex w-full justify-center px-4 md:w-fit'>
          <Button
            fullWidth
            onClick={() => {
              if (userState?.viewer?.type == 'Collaborator') {
                router.push(
                  `/login?email=${encodeURIComponent(
                    userState?.viewer?.email || '',
                  )}&signupMethod=email&redirect=${redirectUrl}`,
                )
              } else if (userState?.viewer?.type == 'Invited') {
                router.push(
                  `/sign-up?email=${encodeURIComponent(
                    userState?.viewer?.email || '',
                  )}&signupMethod=email&redirect=${redirectUrl}`,
                )
              }
              onRequestOk()
            }}
          >
            {flow == 'Login' ? 'Login' : 'Create account'}
          </Button>
        </div>
      </>
    </Base>
  )
}

export default RestrictAccessModal
