// @ts-nocheck
import FlagIcon from '@howdy/core/elements/FlagIcon'

const role = [
  {
    value: 1,
    label: 'Automated QA',
  },
  {
    value: 2,
    label: 'Back-end',
  },
  {
    value: 3,
    label: 'Data Analyst',
  },
  {
    value: 4,
    label: 'Data Engineer',
  },
  {
    value: 5,
    label: 'Data Science',
  },
  {
    value: 6,
    label: 'Dev Ops',
  },
  {
    value: 7,
    label: 'Engineering Manager',
  },
  {
    value: 8,
    label: 'Front-end',
  },
  {
    value: 9,
    label: 'Full-stack',
  },
  {
    value: 10,
    label: 'Game Developer',
  },
  {
    value: 11,
    label: 'Manual QA',
  },
  {
    value: 12,
    label: 'Mobile',
  },
  {
    value: 13,
    label: 'Performance Engineer',
  },
  {
    value: 14,
    label: 'Principal Engineer',
  },
  {
    value: 15,
    label: 'Product Analyst',
  },
  {
    value: 16,
    label: 'Product Manager',
  },
  {
    value: 17,
    label: 'Project Manager',
  },
  {
    value: 18,
    label: 'Software Architect',
  },
  {
    value: 19,
    label: 'Technical Leader',
  },
  {
    value: 20,
    label: 'UX/UI Designer',
  },
  {
    value: 21,
    label: 'Web Designer',
  },
  {
    value: 22,
    label: 'Scrum Master',
  },
  {
    value: 23,
    label: 'Salesforce Dev',
  },
  {
    value: 24,
    label: 'Sales Dev Representative',
  },
  {
    value: 25,
    label: 'Operations Manager',
  },
  {
    value: 26,
    label: 'Account manager',
  },
  {
    value: 27,
    label: 'Accountant',
  },
  {
    value: 28,
    label: 'Invoicing Operations Rep',
  },
  {
    value: 29,
    label: 'Growth Engineer',
  },
  {
    value: 30,
    label: 'Web Scraping',
  },
  {
    value: 31,
    label: 'Websockets',
  },
  {
    value: 32,
    label: 'Customer Success',
  },
  {
    value: 33,
    label: 'Customer Success Manager',
  },
  {
    value: 34,
    label: 'Shopify',
  },
  {
    value: 35,
    label: 'Java + React',
  },
  {
    value: 36,
    label: 'Visual Basic',
  },
  {
    value: 37,
    label: 'Support Engineer',
  },
  {
    value: 38,
    label: 'Contract Administrator',
  },
  {
    value: 39,
    label: 'Web Developer',
  },
  {
    value: 40,
    label: 'NetSuit Dev',
  },
  {
    value: 41,
    label: 'NetSuite Dev',
  },
  {
    value: 42,
    label: 'Appfolio',
  },
  {
    value: 43,
    label: 'Care Team Representative',
  },
  {
    value: 44,
    label: 'Executive assistant',
  },
  {
    value: 45,
    label: 'Retool',
  },
  {
    value: 46,
    label: 'Boomi Dev',
  },
  {
    value: 47,
    label: 'Administrative Assistant',
  },
  {
    value: 48,
    label: 'Virtual Assistant',
  },
  {
    value: 49,
    label: 'Help Desk',
  },
  {
    value: 50,
    label: 'SysAdmin',
  },
  {
    value: 51,
    label: 'Product Owner',
  },
  {
    value: 52,
    label: 'Workday Integration Specialist',
  },
  {
    value: 53,
    label: 'HR Tech Integration',
  },
  {
    value: 54,
    label: 'Backlog Management',
  },
  {
    value: 55,
    label: 'Customer Growth Manager',
  },
]

const tabs = [
  { value: 1, label: 'Candidate intro' },
  { value: 2, label: 'Career' },
  { value: 3, label: 'Education' },
  { value: 4, label: 'Other skills' },
]

const seniority = [
  { value: 1, label: 'Junior' },
  { value: 6, label: 'Junior Mid' },
  { value: 2, label: 'Mid' },
  { value: 7, label: 'Mid Senior' },
  { value: 3, label: 'Senior' },
  { value: 4, label: 'Staff' },
  { value: 5, label: 'Principal' },
]

const technology = [
  {
    label: '.NET',
    value: 1,
  },
  {
    label: 'Adobe',
    value: 2,
  },
  {
    label: 'Adobe XD',
    value: 3,
  },
  {
    label: 'Android',
    value: 4,
  },
  {
    label: 'Android Native',
    value: 5,
  },
  {
    label: 'Angular',
    value: 6,
  },
  {
    label: 'Apollo',
    value: 7,
  },
  {
    label: 'AQA',
    value: 8,
  },
  {
    label: 'Asana',
    value: 9,
  },
  {
    label: 'AWS',
    value: 10,
  },
  {
    label: 'Azure',
    value: 11,
  },
  {
    label: 'BI',
    value: 12,
  },
  {
    label: 'Big Data',
    value: 13,
  },
  {
    label: 'Bugzilla',
    value: 14,
  },
  {
    label: 'C / C++',
    value: 15,
  },
  {
    label: 'C#',
    value: 16,
  },
  {
    label: 'Canva',
    value: 17,
  },
  {
    label: 'ClickUp',
    value: 18,
  },
  {
    label: 'Clojure',
    value: 19,
  },
  {
    label: 'Confluence',
    value: 20,
  },
  {
    label: 'CSS',
    value: 21,
  },
  {
    label: 'Cucumber',
    value: 22,
  },
  {
    label: 'Cypress',
    value: 23,
  },
  {
    label: 'Cypress.io',
    value: 24,
  },
  {
    label: 'Data Engineering',
    value: 25,
  },
  {
    label: 'Data models',
    value: 26,
  },
  {
    label: 'Data Science',
    value: 27,
  },
  {
    label: 'Databases',
    value: 28,
  },
  {
    label: 'Dev Ops',
    value: 29,
  },
  {
    label: 'Django',
    value: 30,
  },
  {
    label: 'Docker',
    value: 31,
  },
  {
    label: 'DQL',
    value: 32,
  },
  {
    label: 'DynamoDB',
    value: 33,
  },
  {
    label: 'eCommerce',
    value: 34,
  },
  {
    label: 'Elasticsearch',
    value: 35,
  },
  {
    label: 'Elixr',
    value: 36,
  },
  {
    label: 'Erlang',
    value: 37,
  },
  {
    label: 'ETL',
    value: 38,
  },
  {
    label: 'Express',
    value: 39,
  },
  {
    label: 'Figma',
    value: 40,
  },
  {
    label: 'Fintech',
    value: 41,
  },
  {
    label: 'Firebase',
    value: 42,
  },
  {
    label: 'Flask',
    value: 43,
  },
  {
    label: 'Flutter',
    value: 44,
  },
  {
    label: 'FTPS',
    value: 45,
  },
  {
    label: 'GCP',
    value: 46,
  },
  {
    label: 'Github',
    value: 47,
  },
  {
    label: 'Golang',
    value: 48,
  },
  {
    label: 'Google Ads',
    value: 49,
  },
  {
    label: 'Google Analytics',
    value: 50,
  },
  {
    label: 'Google Cloud',
    value: 51,
  },
  {
    label: 'Grails',
    value: 52,
  },
  {
    label: 'GraphQL',
    value: 53,
  },
  {
    label: 'Haskell',
    value: 54,
  },
  {
    label: 'Heroku',
    value: 55,
  },
  {
    label: 'Hotwire',
    value: 56,
  },
  {
    label: 'HR Management',
    value: 57,
  },
  {
    label: 'HTML',
    value: 58,
  },
  {
    label: 'Hubspot',
    value: 59,
  },
  {
    label: 'Illustrator',
    value: 60,
  },
  {
    label: 'Invoice Processing',
    value: 61,
  },
  {
    label: 'iOS',
    value: 62,
  },
  {
    label: 'iOS Native',
    value: 63,
  },
  {
    label: 'Java',
    value: 64,
  },
  {
    label: 'Javascript',
    value: 65,
  },
  {
    label: 'Javascript + CSS',
    value: 66,
  },
  {
    label: 'Jenkins',
    value: 67,
  },
  {
    label: 'Jira',
    value: 68,
  },
  {
    label: 'JQuery',
    value: 69,
  },
  {
    label: 'Kotlin',
    value: 70,
  },
  {
    label: 'Kubernetes',
    value: 71,
  },
  {
    label: 'Laravel',
    value: 72,
  },
  {
    label: 'Looker Studio',
    value: 73,
  },
  {
    label: 'Lucidchart',
    value: 74,
  },
  {
    label: 'Machine Learning',
    value: 75,
  },
  {
    label: 'Magento',
    value: 76,
  },
  {
    label: 'Mailchimp',
    value: 77,
  },
  {
    label: 'Meta Ads',
    value: 78,
  },
  {
    label: 'Miro',
    value: 79,
  },
  {
    label: 'Monday',
    value: 80,
  },
  {
    label: 'MongoDB',
    value: 81,
  },
  {
    label: 'Month End Close process',
    value: 82,
  },
  {
    label: 'MySQL',
    value: 83,
  },
  {
    label: 'Neptune Kinesis',
    value: 84,
  },
  {
    label: 'NetSuite',
    value: 85,
  },
  {
    label: 'Next.js',
    value: 86,
  },
  {
    label: 'Nix',
    value: 87,
  },
  {
    label: 'Node',
    value: 88,
  },
  {
    label: 'Node + Angular',
    value: 89,
  },
  {
    label: 'Node + React',
    value: 90,
  },
  {
    label: 'NTT Framework',
    value: 91,
  },
  {
    label: 'Oracle',
    value: 92,
  },
  {
    label: 'Pandas',
    value: 93,
  },
  {
    label: 'Phoenix',
    value: 94,
  },
  {
    label: 'PHP',
    value: 95,
  },
  {
    label: 'Pipelines',
    value: 96,
  },
  {
    label: 'Playwright',
    value: 97,
  },
  {
    label: 'PLC',
    value: 98,
  },
  {
    label: 'Postgres',
    value: 99,
  },
  {
    label: 'Power BI',
    value: 100,
  },
  {
    label: 'Product Management',
    value: 101,
  },
  {
    label: 'Python',
    value: 102,
  },
  {
    label: 'QA',
    value: 103,
  },
  {
    label: 'QuickBooks',
    value: 104,
  },
  {
    label: 'React',
    value: 105,
  },
  {
    label: 'React Native',
    value: 106,
  },
  {
    label: 'Redux',
    value: 107,
  },
  {
    label: 'Relay',
    value: 108,
  },
  {
    label: 'Ruby',
    value: 109,
  },
  {
    label: 'Ruby on Rails',
    value: 110,
  },
  {
    label: 'Ruby on Rails + React',
    value: 111,
  },
  {
    label: 'Rust',
    value: 112,
  },
  {
    label: 'Salesforce',
    value: 113,
  },
  {
    label: 'SAP',
    value: 114,
  },
  {
    label: 'Scala',
    value: 115,
  },
  {
    label: 'Scrum Master',
    value: 116,
  },
  {
    label: 'Selenium',
    value: 117,
  },
  {
    label: 'Service Oriented Architecture',
    value: 118,
  },
  {
    label: 'Shopify',
    value: 119,
  },
  {
    label: 'Site Reliability Engineering',
    value: 120,
  },
  {
    label: 'Sketch',
    value: 121,
  },
  {
    label: 'Spark',
    value: 122,
  },
  {
    label: 'SQL',
    value: 123,
  },
  {
    label: 'Support Engineer',
    value: 124,
  },
  {
    label: 'Swift',
    value: 125,
  },
  {
    label: 'Tableau',
    value: 126,
  },
  {
    label: 'Terraform',
    value: 127,
  },
  {
    label: 'TestLink',
    value: 128,
  },
  {
    label: 'Typescript',
    value: 129,
  },
  {
    label: 'UX/UI',
    value: 130,
  },
  {
    label: 'Unreal Engine',
    value: 135,
  },
  {
    label: 'Vue',
    value: 131,
  },
  {
    label: 'WordPress',
    value: 132,
  },
  {
    label: 'Xamarin',
    value: 133,
  },
  {
    label: 'Zapier',
    value: 134,
  },
  {
    label: 'CRM',
    value: 135,
  },
  {
    label: 'FastAPI',
    value: 136,
  },
  {
    label: 'Xero',
    value: 137,
  },
  {
    label: 'Excel',
    value: 138,
  },
  {
    label: 'SIIGO',
    value: 139,
  },
  {
    label: 'Contai',
    value: 140,
  },
  {
    label: 'Google Workspace',
    value: 141,
  },
  {
    label: 'Microsoft Dynamics AX',
    value: 142,
  },
  {
    label: 'Microsoft Office suite',
    value: 143,
  },
  {
    label: 'Google Suite',
    value: 144,
  },
  {
    label: 'ISP Next',
    value: 145,
  },
]

const ustimezone = [
  { value: 1, label: 'EST' },
  { value: 2, label: 'PST' },
  { value: 3, label: 'CST' },
]

const mainTech = [
  {
    value: 1,
    label: '.NET',
  },
  {
    value: 2,
    label: 'AQA',
  },
  {
    value: 3,
    label: 'AWS',
  },
  {
    value: 4,
    label: 'Adobe',
  },
  {
    value: 5,
    label: 'Adobe XD',
  },
  {
    value: 6,
    label: 'Advertising',
  },
  {
    value: 7,
    label: 'Agile',
  },
  {
    value: 8,
    label: 'Algorithms',
  },
  {
    value: 9,
    label: 'Analisys',
  },
  {
    value: 10,
    label: 'Android',
  },
  {
    value: 11,
    label: 'Android Native',
  },
  {
    value: 12,
    label: 'Angular',
  },
  {
    value: 13,
    label: 'Apollo',
  },
  {
    value: 14,
    label: 'Azure',
  },
  {
    value: 15,
    label: 'BI',
  },
  {
    value: 16,
    label: 'Big Data',
  },
  {
    value: 17,
    label: 'C / C++',
  },
  {
    value: 18,
    label: 'C#',
  },
  {
    value: 19,
    label: 'CI/CD',
  },
  {
    value: 20,
    label: 'CSS',
  },
  {
    value: 21,
    label: 'Clojure',
  },
  {
    value: 22,
    label: 'Coding',
  },
  {
    value: 23,
    label: 'Communication',
  },
  {
    value: 24,
    label: 'Cucumber',
  },
  {
    value: 25,
    label: 'Cypress',
  },
  {
    value: 26,
    label: 'Cypress.io',
  },
  {
    value: 27,
    label: 'DQL',
  },
  {
    value: 28,
    label: 'Data Engineering',
  },
  {
    value: 29,
    label: 'Data Science',
  },
  {
    value: 30,
    label: 'Data models',
  },
  {
    value: 31,
    label: 'Databases',
  },
  {
    value: 32,
    label: 'Decision-Making',
  },
  {
    value: 33,
    label: 'Design Thinking',
  },
  {
    value: 34,
    label: 'Design Patterns',
  },
  {
    value: 35,
    label: 'Dev Ops',
  },
  {
    value: 36,
    label: 'Django',
  },
  {
    value: 37,
    label: 'Docker',
  },
  {
    value: 38,
    label: 'DynamoDB',
  },
  {
    value: 39,
    label: 'ETL',
  },
  {
    value: 40,
    label: 'Elasticsearch',
  },
  {
    value: 41,
    label: 'Elixr',
  },
  {
    value: 42,
    label: 'Erlang',
  },
  {
    value: 43,
    label: 'Express',
  },
  {
    value: 44,
    label: 'FTPS',
  },
  {
    value: 45,
    label: 'Figma',
  },
  {
    value: 46,
    label: 'Fintech',
  },
  {
    value: 47,
    label: 'Firebase',
  },
  {
    value: 48,
    label: 'Flask',
  },
  {
    value: 49,
    label: 'Flutter',
  },
  {
    value: 50,
    label: 'GCP',
  },
  {
    value: 51,
    label: 'Github actions',
  },
  {
    value: 52,
    label: 'Golang',
  },
  {
    value: 53,
    label: 'Grails',
  },
  {
    value: 54,
    label: 'GraphQL',
  },
  {
    value: 55,
    label: 'HR Management',
  },
  {
    value: 56,
    label: 'HTML',
  },
  {
    value: 57,
    label: 'Haskell',
  },
  {
    value: 58,
    label: 'Heroku',
  },
  {
    value: 59,
    label: 'Hotwire',
  },
  {
    value: 60,
    label: 'Illustrator',
  },
  {
    value: 61,
    label: 'Invoice Processing',
  },
  {
    value: 62,
    label: 'JQuery',
  },
  {
    value: 63,
    label: 'Java',
  },
  {
    value: 64,
    label: 'Javascript',
  },
  {
    value: 65,
    label: 'Javascript + CSS',
  },
  {
    value: 66,
    label: 'Jenkins',
  },
  {
    value: 67,
    label: 'Jira',
  },
  {
    value: 68,
    label: 'KPI',
  },
  {
    value: 69,
    label: 'Kotlin',
  },
  {
    value: 70,
    label: 'Kubernetes',
  },
  {
    value: 71,
    label: 'Laravel',
  },
  {
    value: 72,
    label: 'Leadership',
  },
  {
    value: 73,
    label: 'Machine Learning',
  },
  {
    value: 74,
    label: 'Magento',
  },
  {
    value: 75,
    label: 'Market research',
  },
  {
    value: 76,
    label: 'Marketing',
  },
  {
    value: 77,
    label: 'Miro',
  },
  {
    value: 78,
    label: 'MongoDB',
  },
  {
    value: 79,
    label: 'Month End Close process',
  },
  {
    value: 80,
    label: 'MySQL',
  },
  {
    value: 81,
    label: 'NTT Framework',
  },
  {
    value: 82,
    label: 'Negotiation',
  },
  {
    value: 83,
    label: 'Neptune Kinesis',
  },
  {
    value: 84,
    label: 'NetSuite',
  },
  {
    value: 85,
    label: 'Next.js',
  },
  {
    value: 86,
    label: 'Nix',
  },
  {
    value: 87,
    label: 'Node',
  },
  {
    value: 88,
    label: 'Node + Angular',
  },
  {
    value: 89,
    label: 'Node + React',
  },
  {
    value: 90,
    label: 'Oracle',
  },
  {
    value: 91,
    label: 'PHP',
  },
  {
    value: 92,
    label: 'PLC',
  },
  {
    value: 93,
    label: 'PMO',
  },
  {
    value: 94,
    label: 'Pandas',
  },
  {
    value: 95,
    label: 'Peer-review',
  },
  {
    value: 96,
    label: 'Phoenix',
  },
  {
    value: 97,
    label: 'Pipeline',
  },
  {
    value: 98,
    label: 'Pipelines',
  },
  {
    value: 99,
    label: 'Playwright',
  },
  {
    value: 100,
    label: 'Postgres',
  },
  {
    value: 101,
    label: 'Produc Strategy',
  },
  {
    value: 102,
    label: 'Product Lifecicle',
  },
  {
    value: 103,
    label: 'Product Management',
  },
  {
    value: 104,
    label: 'Project Manager',
  },
  {
    value: 105,
    label: 'Project planning',
  },
  {
    value: 106,
    label: 'Python',
  },
  {
    value: 107,
    label: 'QA',
  },
  {
    value: 108,
    label: 'QuickBooks',
  },
  {
    value: 109,
    label: 'React',
  },
  {
    value: 110,
    label: 'React Native',
  },
  {
    value: 111,
    label: 'Redux',
  },
  {
    value: 112,
    label: 'Relational databases',
  },
  {
    value: 113,
    label: 'Relay',
  },
  {
    value: 114,
    label: 'Requirements Managment',
  },
  {
    value: 115,
    label: 'Ruby',
  },
  {
    value: 116,
    label: 'Ruby on Rails',
  },
  {
    value: 117,
    label: 'Ruby on Rails + React',
  },
  {
    value: 118,
    label: 'Rust',
  },
  {
    value: 119,
    label: 'SAP',
  },
  {
    value: 120,
    label: 'SQL',
  },
  {
    value: 121,
    label: 'Salesforce',
  },
  {
    value: 122,
    label: 'Scala',
  },
  {
    value: 123,
    label: 'Scrum Master',
  },
  {
    value: 124,
    label: 'Selenium',
  },
  {
    value: 125,
    label: 'Service Oriented Architecture',
  },
  {
    value: 126,
    label: 'Shopify',
  },
  {
    value: 127,
    label: 'Site Reliability Engineering',
  },
  {
    value: 128,
    label: 'Sketch',
  },
  {
    value: 129,
    label: 'Software design',
  },
  {
    value: 130,
    label: 'Spark',
  },
  {
    value: 131,
    label: 'Support Engineer',
  },
  {
    value: 132,
    label: 'Swift',
  },
  {
    value: 133,
    label: 'Terraform',
  },
  {
    value: 134,
    label: 'Test cases',
  },
  {
    value: 135,
    label: 'Testing plan',
  },
  {
    value: 136,
    label: 'Typescript',
  },
  {
    value: 137,
    label: 'UX/UI',
  },
  {
    label: 'Unreal Engine',
    value: 145,
  },
  {
    value: 138,
    label: 'Vue',
  },
  {
    value: 139,
    label: 'WordPress',
  },
  {
    value: 140,
    label: 'Xamarin',
  },
  {
    value: 141,
    label: 'Zapier',
  },
  {
    value: 142,
    label: 'eCommerce',
  },
  {
    value: 143,
    label: 'iOS',
  },
  {
    value: 144,
    label: 'iOS Native',
  },
  {
    label: 'CRM',
    value: 145,
  },
  {
    label: 'Hubspot',
    value: 146,
  },
  {
    label: 'Xero',
    value: 147,
  },
  {
    label: 'Excel',
    value: 148,
  },
  {
    label: 'SIIGO',
    value: 149,
  },
  {
    label: 'Contai',
    value: 150,
  },
  {
    label: 'Google Workspace',
    value: 151,
  },
  {
    label: 'Microsoft Dynamics AX',
    value: 152,
  },
  {
    label: 'Microsoft Office suite',
    value: 153,
  },
  {
    label: 'Google Suite',
    value: 154,
  },
  {
    label: 'ISP Next',
    value: 155,
  },
]

const englishLevel = [
  { value: 1, label: 'Basic' },
  { value: 2, label: 'Pre Intermediate' },
  { value: 3, label: 'Intermediate' },
  { value: 4, label: 'Upper Intermediate' },
  { value: 5, label: 'Advanced' },
  { value: 6, label: 'Proficient' },
]

const experience = [
  { value: 1, label: '1 year' },
  { value: 2, label: '2 years' },
  { value: 3, label: '3 years' },
  { value: 4, label: '4 years' },
  { value: 5, label: '5 years' },
  { value: 6, label: '6 years' },
  { value: 7, label: '7 years' },
  { value: 8, label: '8 years' },
  { value: 9, label: '9 years' },
  { value: 10, label: '10 years' },
  { value: 11, label: '+10 years' },
]

const countries = [
  'Argentina',
  'Brazil',
  'Chile',
  'Colombia',
  'Ecuador',
  'United States',
  'Mexico',
  'Peru',
  'Uruguay',
  'Venezuela',
].map((country) => ({ value: country, label: country, icon: <FlagIcon country={country} /> }))

const candidateType = [
  { value: 'candidate', label: 'Candidate' },
  { value: 'roster', label: 'Roster' },
]

const visible = [
  { value: true, label: 'Shareable' },
  { value: false, label: 'Not shareable' },
]

const options = {
  role,
  seniority,
  technology,
  ustimezone,
  mainTech,
  englishLevel,
  experience,
  countries,
  tabs,
  candidateType,
  visible,
  howdyEnglishLevel: englishLevel,
  howdySeniority: seniority,
}

const getLabelForValue = (optionsType, _value) => {
  return options[optionsType]?.find(({ value }) => _value === value)?.label
}

const getValueForLabel = (optionsType, _label) => {
  return options[optionsType]?.find(({ label }) => _label === label)?.value
}

export default { ...options, getLabelForValue, getValueForLabel }
