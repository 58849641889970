import { useState } from 'react'
import axios from 'axios'
import cn from 'classnames'
import * as Sentry from '@sentry/nextjs'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'

import Input from '@howdy/core/elements/Input'
import Button from '@howdy/core/elements/Button'
import Loader from '../../../components/loader'
import Disclaimers from '../../my-teams/summary/disclaimers'
import setBillingInfo from '../../../actions/setBillingInfo'
import { useUser } from '../../../contexts/userContext'
import { setCardAsDefault, setupCard } from '../../../utils/paymentInfoFunctions'
import useMobile from '../../../hooks/useMobile'

export default function StripeElementForm({
  billingInfo,
  goBackMethod,
  onSuccessfullCompletion,
  showInvitationModal,
}) {
  const {
    register,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({})

  const [isLoading, setIsLoading] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const { dispatch, userState } = useUser()
  const { data: user } = userState
  const { isMobile } = useMobile()
  const { companyLegalName, billingEmail, billingContactPhone } = billingInfo

  const CARD_ELEMENT_OPTIONS = {
    placeholder: 'Card number',
    style: {
      base: {
        border: '1px solid red',
        borderRadius: '8px',
        color: '#192A3E',
        fontWeight: 500,
        fontFamily: 'Urbanist',
        fontSmoothing: 'antialiased',
        fontSize: '18px',
        '::placeholder': {
          color: '#C1C1C1',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  }

  const handleSubmit = async () => {
    const isValidName = await trigger('Credit Card Holder Name')
    if (!stripe || !elements || !isValidName) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }
    setIsLoading(true)
    const cardElement = elements.getElement(CardElement)
    const cardHolderName = getValues('Credit Card Holder Name')
    const { cardData, zohoContactID, stripeCustomerID } = await setupCard(
      stripe,
      companyLegalName,
      billingEmail,
      billingContactPhone,
      cardElement,
      cardHolderName,
    )
    if (cardData.error) {
      setIsLoading(false)
      toast.error(cardData.error.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      })
      Sentry.captureException(cardData)
    } else {
      try {
        setCardAsDefault(cardData, stripeCustomerID)
        const data = {
          stripeId: stripeCustomerID,
          zohoId: zohoContactID,
          paymentMethod: 'creditCard',
          companyLegalName,
          billingEmail,
          billingContactPhone,
        }
        await axios.put('/company', {
          id: user.company.id,
          partnerId: user.company.partnerId,
          ...data,
        })
        dispatch(setBillingInfo(data))
        setIsLoading(false)
        onSuccessfullCompletion()
      } catch (error) {
        Sentry.captureException(error)
        console.error(error)
      }
    }
  }

  return (
    <>
      <div
        className={cn('flex h-full w-full flex-col items-center justify-center', {
          hidden: !isLoading,
        })}
      >
        {isLoading && (
          <div className='relative flex h-36 w-full flex-col items-center justify-center'>
            <Loader loading={isLoading} />
          </div>
        )}
      </div>
      <div className={'mt-8 ' + (isLoading ? 'hidden' : '')}>
        <div className='md:flex md:flex-row'>
          <Input
            label='Name'
            placeholder='Credit card holder name'
            name='Credit Card Holder Name'
            input={{
              ...register('Credit Card Holder Name', {
                required: 'Required',
                maxLength: {
                  value: 80,
                  message: 'Maximum length is 80',
                },
              }),
            }}
            required='Required'
            error={errors && errors['Credit Card Holder Name']?.message}
          />
        </div>
        <div className='md:flex md:flex-row'>
          <div className='mb-4 md:w-full'>
            <label className='mb-2 flex items-center justify-between text-sm font-normal text-howdy-black'>
              Credit card number
            </label>
            <div className='relative w-full'>
              <CardElement
                options={CARD_ELEMENT_OPTIONS}
                className='w-full'
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </div>
        <Disclaimers invite={showInvitationModal} />
        <div className='mb-7 mt-20 flex flex-col-reverse items-center justify-center gap-5 md:mb-0 md:flex-row'>
          <Button
            fullWidth={isMobile}
            style={'secondary'}
            onClick={() => {
              goBackMethod()
            }}
          >
            Cancel
          </Button>
          <Button
            fullWidth={isMobile}
            onClick={() => {
              handleSubmit()
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  )
}
