import { useEffect, useState } from 'react'

const ToggleButton = ({ active: initialActive, onToggle, id }) => {
  const [active, setActive] = useState(initialActive)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(false)
  }, [active])

  useEffect(() => {
    setActive(initialActive)
  }, [initialActive])

  const handleToggle = async () => {
    if (loading) return

    setLoading(true)
    const newActiveStatus = !active

    setActive(newActiveStatus)

    try {
      await onToggle(newActiveStatus)
    } catch (error) {
      setActive(!newActiveStatus)
    } finally {
      setLoading(false)
    }
  }
  return (
    <div
      id={id}
      className={`h-[25px] w-[50px] rounded-full border ${
        loading ? 'border-[#c1c1c1]' : 'border-[#c1c1c1]'
      } relative cursor-pointer p-[2px] ${active && !loading ? 'bg-white' : 'bg-howdy-[#DCDCDC]'}`}
      onClick={handleToggle}
    >
      <div
        className={`h-[19px] w-[19px] ${
          loading ? 'bg-howdy-disabled-dark' : active ? 'bg-howdy-primary-green' : 'bg-[#c1c1c1]'
        } absolute rounded-full transition-all ${
          active ? 'left-[calc(100%_-_21px)]' : 'left-[2px]'
        }`}
      />
    </div>
  )
}

export default ToggleButton
