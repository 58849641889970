import React, { ReactNode } from 'react'
import NavigationSideBar from '@howdy/core/elements/NavigationSideBar'
import ErrorBoundary from '@howdy/core/elements/ErrorBoundary'
import { Section } from '../NavigationSideBar/types'
import Footer from '../../../../components/Footer'

const LayoutDesktop = ({
  children,
  menuSections,
  news,
  hideFooter,
}: {
  children: ReactNode
  menuSections: Section[]
  news: any
  hideFooter?: boolean
}) => {
  return (
    <div className='relative h-screen overflow-hidden'>
      <div className='absolute left-0 top-0 z-50 h-full'>
        <NavigationSideBar menuSections={menuSections} news={news} />
      </div>

      <div
        id='scrollableDiv'
        className={`ml-16 h-full overflow-y-auto bg-howdy-dark-wheat ${
          !hideFooter ? 'md:h-[calc(100%-24px)]' : ''
        }`}
      >
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>
      {!hideFooter && <Footer />}
    </div>
  )
}

export default LayoutDesktop
