import classNames from 'classnames'
import Portal from '@howdy/core/components/Portal'
import DesktopStepper from '../../components/DesktopStepper'
import MobileStepper from '@howdy/core/components/MobileStepper'

import Modal from '../../components/modal'

export default function SlidesModal({ children, step, steps, display, closeAction }) {
  if (!display) {
    return null
  }
  return (
    <Portal>
      <Modal
        display={display}
        size='2xl'
        width='full'
        rounded={false}
        fullscreen={true}
        className={classNames('!z-[100]')}
      >
        <div
          className={classNames(
            'absolute left-0 z-10 flex w-full content-start justify-between p-[50px]',
            'xsm:bg-gradient-to-b xsm:from-howdy-wheat xsm:to-transparent',
          )}
        >
          <img
            src='/howdy-logo.svg'
            className={classNames('xsm:m-0 xsm:h-[40px] xsm:p-0', 'mt-4 h-[50px] pl-4')}
          />
          <button className='h-fit cursor-pointer' onClick={closeAction}>
            <img src='/close.svg' width={15} height={24} />
          </button>
        </div>
        <div className='flex h-full w-full flex-col lg:flex-row'>
          <div
            style={{
              backgroundImage: `url("/Photo_slide_${step != steps.length - 1 ? step : 100}.png")`,
            }}
            className={classNames(
              `relative flex h-[30%] w-full justify-between lg:h-full lg:w-3/5`,
              'flex-col-reverse overflow-hidden bg-cover bg-top bg-no-repeat lg:flex-row',
            )}
          >
            <div
              className={classNames(
                'relative flex h-full w-[450px] flex-col justify-center bg-gradient-to-r from-[#F3DDC4] via-[rgba(243,221,196,0.83)] to-transparent pb-6 pl-6 pt-[50px]',
                'xsm:hidden',
              )}
            >
              {steps[step].stepperTitle != undefined && (
                <div className='flex h-full flex-col justify-end p-[50px]'>
                  <DesktopStepper
                    stepsColor={'[#FF9E00]'}
                    joinColor='border-black'
                    className={'text-black'}
                    minHeight={'min-h-[380px]'}
                    maxHeight={'max-h-[480px]'}
                    steps={steps
                      .map(({ stepperTitle: title }) => ({ title }))
                      .filter((element) => !!element.title)}
                    currentStep={step}
                  />
                </div>
              )}
            </div>
          </div>
          <div
            className={classNames(
              'relative flex h-[70%] w-full flex-col bg-[#F3DDC4] lg:-top-[100px]  lg:h-[calc(100%_+_100px)] lg:w-2/5 lg:pt-[100px]',
            )}
          >
            <img
              src='/spiral.svg'
              className='absolute -left-[102px] -top-[98px] rotate-180 lg:top-[unset] lg:rotate-0'
            />
            <div
              className={classNames(
                'overflow-hidden',
                'mt-28 flex w-full grow flex-col justify-center px-[70px] font-worksans',
                'z-20 xsm:mt-8 xsm:justify-start xsm:pl-4 xsm:pr-4',
              )}
            >
              {children}
            </div>
            <MobileStepper
              numberOfSteps={steps.length}
              currentStep={step}
              className={classNames('hidden', 'lg:mt-4 xsm:mb-6 xsm:flex xsm:justify-center ')}
            />
            <p
              className={classNames(
                'flex flex-row-reverse p-[20px] text-black',
                'xsm:hidden xsm:justify-center xsm:text-xs',
              )}
            >
              © Howdy
            </p>
          </div>
        </div>
      </Modal>
    </Portal>
  )
}
