module.exports = {
  mongoURL: `${process.env.ORMONGO_URL}`,
  sessionSecret: `${process.env.SESSION_SECRET}`,
  sendgridApiKey: `${process.env.SENDGRID_API_KEY}`,
  sendgridTemplateIdInvite: `${process.env.SENDGRID_TEMPLATE_ID_INVITE}`,
  sendgridTemplateIdSignUpInvite: `${process.env.SENDGRID_TEMPLATE_SIGUP_INVITE}`,
  sendgridTemplateIdSignInInvite: `${process.env.SENDGRID_TEMPLATE_SIGNIN_INVITE}`,
  sendgridTemplateIdOwnershipTransferred: `${process.env.SENDGRID_TEMPLATE_ID_OWNERSHIP_TRANSFERRED}`,
  sendgridTemplateIdFillPaymentInvitation: `${process.env.SENDGRID_TEMPLATE_ID_FILL_PAYMENT_INVITATION}`,
  sendgridTemplateIdFillPaymentReminder: `${process.env.SENDGRID_TEMPLATE_ID_FILL_PAYMENT_REMINDER}`,
  sendgridSenderEmailInvite: `${process.env.SENGRID_SENDER_EMAIL_INVITE}`,
  sendgridSenderEmailNoreply: `${process.env.SENGRID_SENDER_EMAIL_NOREPLY}`,
  sendgridSenderEmailSales: `${process.env.SENGRID_SENDER_EMAIL_SALES}`,
  emailCompanyInfoCompleted: `${process.env.EMAIL_COMPANY_INFO_COMPLETED}`,
  emailOnboardingCompleted: `${process.env.EMAIL_ONBOARDING_COMPLETED}`,
  airtableApiKey: `${process.env.AIRTABLE_API_KEY}`,
  airtableAppId: `${process.env.AIRTABLE_APP_ID}`,
  bearerToken: `${process.env.BEARER_TOKEN}`,
  gaId: `${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`,
  googleSecret: `${process.env.GOOGLE_SECRET}`,
  googleClientId: `${process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}`,
  slackMessageWebhookUrl: `${process.env.SLACK_MESSAGE_WEBHOOK_URL}`,
  slackContactUsWebhookUrlHelp: `${process.env.SLACK_CONTACT_US_HELP}`,
  slackContactUsWebhookUrlSuggestions: `${process.env.SLACK_CONTACT_US_SUGGESTIONS}`,
  slackRecommendationsAccepted: `${process.env.SLACK_RECOMMENDATIONS_ACCEPTED}`,
  slackStoreOrders: `${process.env.SLACK_STORE_ORDERS}`,
  plaidEnv: `${process.env.PLAID_ENV}`,
  plaidClientId: `${process.env.PLAID_CLIENT_ID}`,
  plaidSecret: `${process.env.PLAID_SECRET}`,
  stripePublicKey: `${process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY}`,
  stripeSecret: `${process.env.STRIPE_SECRET}`,
  zohoApiUrl: `${process.env.ZOHO_API_URL}`,
  zohoOAuthClientID: `${process.env.ZOHO_OAUTH_CLIENT_ID}`,
  zohoOAuthClientSecret: `${process.env.ZOHO_OAUTH_CLIENT_SECRET}`,
  zohoOAuthCode: `${process.env.ZOHO_OAUTH_CODE}`,
  zohoOrganizationId: `${process.env.ZOHO_ORG_ID}`,
  zohoRedirectUri: `${process.env.ZOHO_REDIRECT_URI}`,
  sqlHost: `${process.env.SQL_HOST}`,
  sqlPort: `${process.env.SQL_PORT}`,
  sqlUser: `${process.env.SQL_USER}`,
  sqlPassword: `${process.env.SQL_PASSWORD}`,
  sqlDatabase: `${process.env.SQL_DATABASE}`,

  sqlAstroHost: `${process.env.SQL_ASTRO_HOST}`,
  sqlAstroUser: `${process.env.SQL_ASTRO_USER}`,
  sqlAstroPassword: `${process.env.SQL_ASTRO_PASSWORD}`,
  sqlAstroDatabase: `${process.env.SQL_ASTRO_DATABASE}`,
  sqlDmtDatabase: `${process.env.SQL_DMT_DATABASE}`,

  sendgridTemplateIdOnboardingStepCompleted: `${process.env.SENDGRID_ONBOARDING_STEP_COMPLETED_TEMPLATE_ID}`,
  sendgridTemplateIdOnboardingCompleted: `${process.env.SENDGRID_ONBOARDING_COMPLETED_TEMPLATE_ID}`,
  sendgridTemplateIdNewTeamSales: `${process.env.SENDGRID_TEMPLATEID_SALES}`,
  sendgridTemplateIdNewTeamPartner: `${process.env.SENDGRID_TEMPLATEID_PARTNER}`,
  sendgridTemplateIdPassword: `${process.env.SENDGRID_TEMPLATEID_PASSWORD}`,
  airtableDmtBaseUrl: `${process.env.AIRTABLE_DMT_URL}`,
  airtableDmtApiKey: `${process.env.AIRTABLE_DMT_API_KEY}`,
  airtableDmtBase: `${process.env.AIRTABLE_DMT_BASE_ID}`,
  HOWDY_APP_URL: `${process.env.HOWDY_APP_URL}`,
  CC_RECOMMENDATIONS_EMAIL: `${process.env.CC_RECOMMENDATIONS_EMAIL}`,
  sendgridTemplateIdNewInvite: `${process.env.SENDGRID_TEMPLATEID_NEW_INVITE}`,
  sendgridTemplateIdViewerAccessRequest: `${process.env.SENDGRID_TEMPLATEID_VIEWER_ACCESS_REQUEST}`,
  sendgridTemplateIdViewerAccessGranted: `${process.env.SENDGRID_TEMPLATEID_VIEWER_ACCESS_GRANTED}`,
  sendgridTemplateIdViewerAccessDenied: `${process.env.SENDGRID_TEMPLATEID_VIEWER_ACCESS_DENIED}`,
  sendgridTemplateIdCollaboratorAccessRequest: `${process.env.SENDGRID_TEMPLATEID_COLLABORATOR_ACCESS_REQUEST}`,
  sendgridTemplateIdCollaboratorAccessGranted: `${process.env.SENDGRID_TEMPLATEID_COLLABORATOR_ACCESS_GRANTED}`,
  sendgridTemplateIdCollaboratorAccessDenied: `${process.env.SENDGRID_TEMPLATEID_COLLABORATOR_ACCESS_DENIED}`,
  sendgridTemplateIdOwnerInvite: `${process.env.SENDGRID_TEMPLATEID_OWNER_INVITE}`,
}

// This env variables must be set for sentry to work,
// they are not used directly in the project that's why they are not exported here
// SENTRY_AUTH_TOKEN
// SENTRY_API_KEY <optional>
// SENTRY_DSN <optional>
// SENTRY_URL
// SENTRY_ORG
// SENTRY_PROJECT
