import React from 'react'

const CloseIcon = ({ width = 32, height = 32, color = 'currentColor', strokeWidth = '1.5' }) => {
  return (
    <svg
      className='transition-all duration-150'
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25 7L7 25'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25 25L7 7'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default CloseIcon
