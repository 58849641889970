// hooks/useAuth.js
import { useMsal, MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'

const msalConfig = {
  auth: {
    clientId: '75b9fd71-d7ce-4145-b0db-fc742be9bd33',
    authority: 'https://login.microsoftonline.com/984bb51b-9744-46d9-a8b8-fd0be98bd26f',
  },
}

const loginRequest = {
  scopes: ['User.Read'],
}

export const useMSAuth = ({
  onSuccess,
  onError,
}: {
  onSuccess: ({ accessToken }) => void
  onError?: (error: Error) => void
}) => {
  const { instance } = useMsal()

  const login = async () => {
    return instance.loginPopup(loginRequest).then(onSuccess).catch(onError)
  }

  const logout = () => {
    instance.logout()
  }

  const getProfile = async () => {
    const accounts = instance.getAllAccounts()
    if (accounts.length > 0) {
      const accessToken = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })

      const response = await fetch('https://graph.microsoft.com/v1.0/me', {
        headers: {
          Authorization: `Bearer ${accessToken.accessToken}`,
        },
      })

      return await response.json()
    }
    return null
  }

  return { login, logout, getProfile }
}

const msalInstance = new PublicClientApplication(msalConfig)

function MSAuthProvider({ children }) {
  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>
}

export default MSAuthProvider
