import React from 'react'
import Eye from './Icons/Eye'
import EyeSlash from './Icons/Eye-slash'

export interface VisibilityEyeProps {
  visible?: boolean
  size?: number
  name?: string
  onClick?: () => void
}

function VisibilityEye({ visible, name, size = 20, onClick }: VisibilityEyeProps) {
  return (
    <div onClick={onClick} className='cursor-pointer' data-name={name}>
      {visible ? (
        <Eye color='rgb(68 147 134)' width={size} height={size} />
      ) : (
        <EyeSlash color='#A3A3A3' width={size} height={size} />
      )}
    </div>
  )
}

export default VisibilityEye
