import React from 'react'

export default function CountryVenezuela({ width = 32, height = 32 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_163_1579)'>
        <mask
          style={{ maskType: 'luminance' }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width={32}
          height={32}
        >
          <path
            d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_163_1579)'>
          <path
            d='M0 9.04375L15.9563 6.7625L32 9.04375V22.9562L15.6562 25.4375L0 22.9562V9.04375Z'
            fill='#0052B4'
          />
          <path d='M0 0H32V9.04375H0V0Z' fill='#FFDA44' />
          <path d='M0 22.9563H32V32H0V22.9563Z' fill='#D80027' />
          <path
            d='M27.7125 19.15L26.8625 19.8125L27.2375 20.8187L26.3438 20.2187L25.5 20.8875L25.7938 19.8562L24.9062 19.2562L25.975 19.2187L26.2687 18.1875L26.6437 19.1937L27.7125 19.15ZM25.5438 15.4L24.9813 16.3062L25.6688 17.1312L24.625 16.875L24.0625 17.7875L23.9813 16.725L22.9438 16.4562L23.9375 16.0562L23.8625 14.9937L24.55 15.8062L25.5438 15.4ZM22.2313 12.6187L22.0063 13.6687L22.9375 14.1937L21.875 14.3062L21.65 15.3562L21.2125 14.375L20.15 14.4875L20.9438 13.7687L20.5063 12.7937L21.4312 13.3312L22.2313 12.6187ZM18.1688 11.1375L18.3125 12.2L19.375 12.3875L18.4062 12.8562L18.5562 13.9187L17.8062 13.1437L16.8438 13.6187L17.3438 12.6687L16.6063 11.9L17.6625 12.0875L18.1688 11.1375ZM13.8375 11.1375L14.3375 12.0812L15.4 11.8937L14.65 12.6687L15.15 13.6187L14.1875 13.1437L13.4438 13.9187L13.5938 12.8562L12.6313 12.3875L13.6875 12.2L13.8375 11.1375ZM9.775 12.6187L10.5688 13.3375L11.4938 12.8L11.0563 13.7812L11.8563 14.4937L10.7938 14.3812L10.3563 15.3625L10.125 14.3187L9.0625 14.2062L9.9875 13.675L9.775 12.6187ZM6.45625 15.4L7.45 15.8L8.1375 14.9875L8.0625 16.05L9.0625 16.45L8.01875 16.7125L7.94375 17.775L7.375 16.875L6.33125 17.1375L7.01875 16.3125L6.45625 15.4ZM4.2875 19.15L5.3625 19.1812L5.73125 18.1812L6.025 19.2062L7.09375 19.2437L6.20625 19.8437L6.5 20.8812L5.65625 20.2187L4.76875 20.8187L5.1375 19.8187L4.2875 19.15Z'
            fill='#EEEEEE'
          />
        </g>
      </g>
      <defs>
        <clipPath>
          <rect width={32} height={32} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
