import React from 'react'

export default function Buildings({ width = 22, height = 23 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.8772 18.8379H20.5636'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.5551 18.838V4.00247C12.5551 3.82362 12.4848 3.6521 12.3597 3.52564C12.2345 3.39917 12.0648 3.32813 11.8878 3.32812H3.87929C3.70229 3.32812 3.53254 3.39917 3.40738 3.52564C3.28223 3.6521 3.21191 3.82362 3.21191 4.00247V18.838'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.2289 18.8378V9.397C19.2289 9.21815 19.1586 9.04663 19.0334 8.92017C18.9083 8.7937 18.7385 8.72266 18.5615 8.72266H12.5552'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.88135 6.69922H8.55084'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.21631 12.0938H9.8858'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.88135 15.4668H8.55084'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.2246 15.4668H16.5594'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.2246 12.0938H16.5594'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
