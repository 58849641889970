import React from 'react'
import FlagIcons from '@howdy/core/elements/Icons/Flags/FlagsIcons'

export default function FlagIcon({
  country = 'Placeholder',
  width = 32,
  height = 32,
}: {
  country: string
  width?: number
  height?: number
}) {
  const Flag = FlagIcons[country as keyof typeof FlagIcons] || FlagIcons['Placeholder']
  return (
    <div className={`overflow-hidden rounded-full w-[${width}px] h-[${height}px] md:mx-auto`}>
      {Flag({ width, height })}
    </div>
  )
}
