import React from 'react'

export default function Lock({ width = 31, height = 30 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 31 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Lock'>
        <path
          id='Vector'
          d='M24.875 10.3125H6.125C5.60723 10.3125 5.1875 10.7322 5.1875 11.25V24.375C5.1875 24.8928 5.60723 25.3125 6.125 25.3125H24.875C25.3928 25.3125 25.8125 24.8928 25.8125 24.375V11.25C25.8125 10.7322 25.3928 10.3125 24.875 10.3125Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M11.2812 10.3125V6.09375C11.2813 4.97487 11.7257 3.90181 12.5169 3.11064C13.3081 2.31947 14.3811 1.875 15.5 1.875C16.6189 1.875 17.6919 2.31947 18.4831 3.11064C19.2743 3.90181 19.7188 4.97487 19.7188 6.09375V10.3125'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_3'
          d='M16.6094 17.8125C16.6094 18.4252 16.1127 18.9219 15.5 18.9219C14.8873 18.9219 14.3906 18.4252 14.3906 17.8125C14.3906 17.1998 14.8873 16.7031 15.5 16.7031C16.1127 16.7031 16.6094 17.1998 16.6094 17.8125Z'
          fill='black'
          stroke='currentColor'
          strokeWidth='0.125'
        />
      </g>
    </svg>
  )
}
