import { createContext, useContext, useEffect, useReducer } from 'react'
import produce from 'immer'
import LogRocket from 'logrocket'

export const UserContext = createContext()

export const userInitialState = {
  data: {},
  viewer: null,
  isLoading: false,
  hasFetched: false,
  error: null,
}

const reducer = (draft, action) => {
  switch (action.type) {
    case 'SET_USER':
      draft.data = {
        ...draft.data,
        ...action.payload,
      }
      draft.hasFetched = true
      break
    case 'SET_VIEWER':
      draft.viewer = action.payload
        ? {
            type: action.payload.type,
            email: action.payload.email,
          }
        : null
      break
    case 'SET_LOADING':
      draft.isLoading = action.payload
      break
    case 'SET_ERROR':
      draft.error = action.payload
      break
    case 'SET_HAS_FETCHED':
      draft.hasFetched = action.payload
      break
    case 'SET_COMPANY_NAME':
      draft.data.company.name = action.payload
      break
    case 'SET_COMPANY_COLLABORATOR':
      draft.data = {
        ...draft.data,
        ...(draft.data.id === action.payload.id ? action.payload : {}),
        company: {
          ...draft.data.company,
          users: draft.data.company.users.map((user) => {
            return user.id === action.payload.id ? { ...user, ...action.payload } : user
          }),
        },
      }
      break
    case 'SET_BILLING_INFO':
      draft.data.company = { ...draft.data.company, ...action.payload }
      break
    case 'SET_COMPANY_ORDERS':
      draft.data.company.orders = action.payload
      break
    case 'SET_COMPANY_WHERE_HEARD_ABOUT_US':
      draft.data.company.whereHeardAboutUs = action.payload
      break
    default:
  }
}

export const userReducer = produce(reducer)

export const UserProvider = ({ children }) => {
  const [userState, dispatch] = useReducer(userReducer, userInitialState)
  const userData = userState?.data
  useEffect(() => {
    if (userData.id || userData.superuserId) {
      LogRocket.identify(userData.superuser ? userData.superuserId : userData.id, {
        name: userData.superuser
          ? userData.superuserEmail
          : `${userData.firstName} ${userData.lastName}`,
        email: userData.superuser ? userData.superuserEmail : userData.email,
        company: userData.company?.name,
        companyId: userData.company?.id,
        superuser: userData.superuser,
      })
    }
  }, [userData.id, userData.superuserId])

  return <UserContext.Provider value={{ userState, dispatch }}>{children}</UserContext.Provider>
}

export function useUser() {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error(
      `Your component should be a child of "UserProvider" to be able to use "useUser" inside of it.
      Make sure to wrap your component or The "App" component with <UserProvider> ...</UserProvider>`,
    )
  }
  return context
}
