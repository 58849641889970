import Button from '@howdy/core/elements/Button'
import Circle from '@howdy/core/elements/Circle'
import Base from '@howdy/core/elements/Modal/Base'
import VideoCamera from '@howdy/core/elements/Icons/VideoCamera'

const TutorialVideoModal = ({ display, closeOnClick }) => {
  return (
    <Base isOpen={display} onRequestClose={closeOnClick}>
      <div className='flex w-[684px] max-w-full flex-col px-4 pb-5 md:px-8'>
        <div className='flex w-full justify-center pb-4'>
          <Circle borderColor='#DCDCDC' bgColor='white' className='text-howdy-primary-green'>
            <VideoCamera />
          </Circle>
        </div>
        <iframe
          height='418'
          className='flex-grow'
          src={`${process.env.NEXT_PUBLIC_VIDEO_TUTORIAL_LINK}?autoplay=1`}
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay;'
          allowFullScreen
        ></iframe>
        <div className='mt-5 flex justify-center'>
          <Button style={'tertiary'} onClick={closeOnClick}>
            Close
          </Button>
        </div>
      </div>
    </Base>
  )
}

export default TutorialVideoModal
