import cn from 'classnames'
import classes from '../styles/Loader.module.css'

export default function Loader({ loading, children, className }) {
  if (loading) {
    return (
      // <div className={cn('text-center text-lg font-medium text-gray-600 uppercase animate-pulse', className)}>
      <div className={classes.loaderContainer}>
        <div className={classes.loader}>Loading...</div>
      </div>
    )
  }

  return children
}
