import Image from 'next/image'
import { useUser } from '../../contexts/userContext'
import Portal from '@howdy/core/components/Portal'
import Warning from '@howdy/core/elements/Icons/Warning'
import { useState } from 'react'

function SuperuserMessage() {
  const { userState: user } = useUser()
  const [open, setOpen] = useState(false)
  return (
    <>
      {user.data.superuser && (
        <Portal>
          <div
            className={`fixed bottom-14 right-0 z-50 ml-3 flex w-auto gap-3 overflow-hidden rounded-l-lg bg-[#FFB133] px-3 py-2 transition-all md:absolute md:rounded-lg`}
            onClick={() => setOpen(!open)}
          >
            <div className='flex h-[55px] w-[55px] items-center justify-center rounded-full bg-white text-[#FFB133]'>
              <Warning />
            </div>
            {open && (
              <span className='w-2/3 self-center font-worksans text-lg font-bold'>{`You are logged in under ${
                user.data.company.name ? user.data.company.name : user.data.company.users[0].email
              }`}</span>
            )}
          </div>
        </Portal>
      )}
    </>
  )
}

export default SuperuserMessage
