import React, { ButtonHTMLAttributes } from 'react'
import clsx from 'clsx'

interface ButtonStyles {
  primary: string
  secondary: string
  tertiary: string
}

const typesClasses: ButtonStyles = {
  primary: clsx(
    'bg-black text-white',
    'py-[12px] px-[28px]',
    'leading-[28px] text-base',
    'group-hover:bg-howdy-light-green',
    'group-active:bg-howdy-primary-green',
  ),
  secondary: clsx(
    'bg-white text-black border border-black',
    'py-[11px] px-[27px]',
    'leading-[28px]',
    'group-hover:text-white group-hover:bg-howdy-light-green group-hover:border-none group-hover:py-[12px] group-hover:px-[28px]',
    'group-active:text-white group-active:bg-howdy-primary-green group-active:border-none group-active:py-[12px] group-active:px-[28px]',
  ),
  tertiary: clsx(
    'bg-transparent text-black font-bold px-0 py-[12px] decoration-1 underline-offset-2',
    'underline',
    'group-hover:text-howdy-light-green',
    'group-active:text-howdy-primary-green',
  ),
}

const disabledStyles = {
  primary: '!bg-howdy-disabled !text-howdy-gray',
  secondary: '!bg-howdy-disabled !text-howdy-gray !border-howdy-gray',
  tertiary: '!text-howdy-gray',
}

export default function Button({
  children,
  style = 'primary',
  type = 'button',
  disabled,
  onClick,
  fullWidth = false,
  className,
  wrapperClassName,
}: ButtonHTMLAttributes<any> & {
  style?: 'primary' | 'secondary' | 'tertiary'
  fullWidth?: boolean
  className?: string
  wrapperClassName?: string
}) {
  const classNames = typesClasses[style as keyof typeof typesClasses]
  const disabledClassNames = disabled ? disabledStyles[style as keyof typeof typesClasses] : ''

  return (
    <div
      className={`${disabled ? '' : 'group'} ${fullWidth ? 'w-full' : ''} ${
        wrapperClassName ? wrapperClassName : ''
      }`}
    >
      <button
        onClick={onClick}
        disabled={disabled}
        type={type}
        className={clsx(
          { 'w-full': fullWidth },
          'rounded-full',
          'font-bold',
          'transition-transform',
          'transition-shadow',
          'transition-colors',
          'duration-75',
          'ease-in',
          classNames,
          className,
          disabledClassNames,
        )}
      >
        <div className='flex min-h-[28px] justify-center gap-2 font-bold'>{children}</div>
      </button>
    </div>
  )
}
