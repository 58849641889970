const { default: axios } = require('axios')

const getPlaidToken = async (urlHash) => {
  const response = await axios.get(`/payment-info/plaid-token?urlHash=${urlHash}`)
  const { link_token } = response.data
  return link_token
}

const setupCard = async (
  stripe,
  companyLegalName,
  billingEmail,
  billingContactPhone,
  cardElement,
  cardHolderName,
  zohoId,
  stripeId,
) => {
  const response = await axios.get(
    `/payment-info/get-client-secret-credit-card?companyLegalName=${companyLegalName}&billingAdminEmail=${encodeURIComponent(
      billingEmail,
    )}&billingAdminPhone=${billingContactPhone}${zohoId ? '&zohoId=' + zohoId : ''}${
      stripeId ? '&stripeId=' + stripeId : ''
    }`,
  )
  const { clientSecretData, stripeCustomerID, zohoContactID } = response.data
  const cardData = await stripe.confirmCardSetup(clientSecretData.client_secret, {
    payment_method: {
      card: cardElement,
      billing_details: {
        name: cardHolderName,
        email: billingEmail,
        phone: billingContactPhone,
      },
    },
  })
  return { cardData, stripeCustomerID, zohoContactID }
}

const setCardAsDefault = async (cardData, stripeCustomerID) => {
  await axios.post(`/payment-info/set-as-default`, { data: cardData, stripeCustomerID })
}

const saveAchData = async (
  public_token,
  metadata,
  companyLegalName,
  billingEmail,
  billingContactPhone,
  zohoId,
  stripeId,
) => {
  const response = await axios.post(`/payment-info/save-ach-data`, {
    public_token,
    metadata,
    companyLegalName,
    billingAdminEmail: billingEmail,
    billingAdminPhone: billingContactPhone,
    zohoId: zohoId,
    stripeId: stripeId,
  })
  return response.data
}

module.exports = {
  getPlaidToken,
  setupCard,
  setCardAsDefault,
  saveAchData,
}
