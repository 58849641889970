import React from 'react'

export default function Success() {
  return (
    <svg width='23' height='16' viewBox='0 0 23 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.3125 1.4375L8.1875 14.5625L1.625 8'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
