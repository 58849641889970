export const required = (value: any, message: string) => (value ? undefined : message)

const phoneRegex = /^\+[1-9]\d{10,14}$/

export const isPhone = (
  value: any,
  message: string = 'Should be international number with +. Ex: +503123456789',
) => (!!value && phoneRegex.test(value) ? undefined : message)

export const isNotFuture = (value: any) => {
  return new Date(value) > new Date() ? "Can't be in the future" : undefined
}

export const maxLength = (
  value: any,
  maxChars: number,
  message: string = `Should contain less than ${maxChars} characters`,
) => ((value?.length ?? 0) <= maxChars ? undefined : message)
