import React from 'react'

export default function UserPlus({ width = 31, height = 30 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 31 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='UserPlus'>
        <path
          id='Vector'
          d='M23.9375 15.9375H29.5625'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M26.75 13.125V18.75'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_3'
          d='M13.1562 18.75C17.0395 18.75 20.1875 15.602 20.1875 11.7188C20.1875 7.8355 17.0395 4.6875 13.1562 4.6875C9.273 4.6875 6.125 7.8355 6.125 11.7188C6.125 15.602 9.273 18.75 13.1562 18.75Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_4'
          d='M3.10156 23.4376C4.3335 21.9693 5.87207 20.7887 7.60914 19.9786C9.3462 19.1686 11.2396 18.7488 13.1562 18.7488C15.0729 18.7488 16.9663 19.1686 18.7034 19.9786C20.4404 20.7887 21.979 21.9693 23.2109 23.4376'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  )
}
