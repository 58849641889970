import React from 'react'

export default function CountryBrazil({ width = 32, height = 32 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_163_1506)'>
        <mask
          style={{ maskType: 'luminance' }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width={32}
          height={32}
        >
          <path
            d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_163_1506)'>
          <path d='M0 0H32V32H0V0Z' fill='#6DA544' />
          <path d='M16 6.2625L29.2188 16L16 25.7375L2.78125 16L16 6.2625Z' fill='#FFDA44' />
          <path
            d='M10.8875 13.8125C10.5765 14.5277 10.4231 15.3015 10.4375 16.0813L20.5625 19.1938C21.0086 18.5566 21.3152 17.8325 21.4625 17.0688C18.925 12.9875 13.9813 12.05 10.8938 13.8188L10.8875 13.8125Z'
            fill='#EEEEEE'
          />
          <path
            d='M15.9812 10.4375C15.0672 10.4398 14.1677 10.6674 13.3625 11.1C12.2542 11.698 11.3817 12.6543 10.8875 13.8125C12.7812 13.4104 14.7462 13.4991 16.5959 14.0701C18.4457 14.6411 20.1187 15.6755 21.4562 17.075C21.7047 15.8097 21.5059 14.4974 20.8937 13.3625C20.4163 12.476 19.7072 11.7357 18.8421 11.2205C17.9769 10.7054 16.9881 10.4348 15.9812 10.4375ZM13.25 15.6563C12.2963 15.6531 11.3477 15.7964 10.4375 16.0813C10.448 16.9736 10.6731 17.8504 11.0938 18.6375C11.4399 19.2812 11.9095 19.8504 12.4757 20.3125C13.0419 20.7746 13.6937 21.1206 14.3937 21.3307C15.0937 21.5408 15.8282 21.6109 16.5553 21.537C17.2824 21.4631 17.9878 21.2467 18.6313 20.9C19.3951 20.4887 20.0522 19.9044 20.55 19.1938C19.6727 18.0985 18.5623 17.2126 17.2995 16.6007C16.0366 15.9887 14.6532 15.6661 13.25 15.6563Z'
            fill='#0052B4'
          />
        </g>
      </g>
      <defs>
        <clipPath>
          <rect width={32} height={32} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
