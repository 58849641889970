import React from 'react'

export default function CreditCard({ width = 31, height = 30, color = 'currentColor' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 31 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='CreditCard'>
        <path
          id='Vector'
          d='M26.75 6.5625H4.25C3.73223 6.5625 3.3125 6.98223 3.3125 7.5V22.5C3.3125 23.0178 3.73223 23.4375 4.25 23.4375H26.75C27.2678 23.4375 27.6875 23.0178 27.6875 22.5V7.5C27.6875 6.98223 27.2678 6.5625 26.75 6.5625Z'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M20.1875 19.6875H23.9375'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_3'
          d='M14.5625 19.6875H16.4375'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_4'
          d='M3.3125 11.3555H27.6875'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  )
}
