import React from 'react'

export default function CountryColombia({ width = 32, height = 32 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_163_1523)'>
        <mask
          style={{ maskType: 'luminance' }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width={32}
          height={32}
        >
          <path
            d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_163_1523)'>
          <path d='M0 24L15.9875 22.1438L32 24V32H0V24Z' fill='#D80027' />
          <path d='M0 16L16.2188 14.0625L32 16V24H0V16Z' fill='#0052B4' />
          <path d='M0 0H32V16H0V0Z' fill='#FFDA44' />
        </g>
      </g>
      <defs>
        <clipPath>
          <rect width={32} height={32} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
