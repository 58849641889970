import React from 'react'

export default function CountryUruguay({ width = 32, height = 32 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_163_1570)'>
        <mask
          style={{ maskType: 'luminance' }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width={32}
          height={32}
        >
          <path
            d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_163_1570)'>
          <path
            d='M0 16L16 0H32V3.48125L30.7062 5.6375L32 7.65V11.825L30.675 13.8687L32 16V20.175L30.5 22.1562L32 24.35V28.5187L15.8062 30.2875L0 28.5187V24.35L1.69375 22.2687L0 20.175V16Z'
            fill='#338AF3'
          />
          <path
            d='M16 16H32V11.825H14.8062L16 16ZM14.8062 7.65H32V3.48125H14.8062V7.65ZM32 32V28.5187H0V32H32ZM0 24.35H32V20.175H0V24.35Z'
            fill='white'
          />
          <path d='M0 0H16V16H0V0Z' fill='white' />
          <path
            d='M13.9125 9.3625L11.9563 10.2812L13 12.175L10.875 11.7688L10.6063 13.9125L9.13125 12.3375L7.65 13.9125L7.38125 11.7688L5.2625 12.175L6.3 10.2812L4.35 9.3625L6.3 8.44375L5.2625 6.55L7.3875 6.95625L7.65 4.8125L9.13125 6.39375L10.6063 4.8125L10.875 6.95625L13 6.55L11.9563 8.44375L13.9125 9.3625Z'
            fill='#FFDA44'
          />
        </g>
      </g>
      <defs>
        <clipPath>
          <rect width={32} height={32} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
