import { useState } from 'react'
import axios from 'axios'
import PhoneForm from './phoneForm'
import CodeForm from './codeForm'
import { toast } from 'react-toastify'
import { useUser } from '../../contexts/userContext'
import setUser from '../../actions/setUser'

const PhoneVerificationForm = ({ handleBack, handleSucces, phoneNumber }) => {
  const [phone, setPhone] = useState('')
  const { dispatch, userState: user } = useUser()
  const displayToast = (message, toastMethod, showInCenter) => {
    toastMethod(message, {
      className: 'toast-blue toast-full',
      position: showInCenter ? 'bottom-center' : 'bottom-left',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
    })
  }

  const sendVerificationCode = async (phoneNumber) => {
    setPhone(phoneNumber)
    try {
      await axios.post('/phone/sendCode', {
        phoneNumber: phoneNumber,
      })
      displayToast('Verification code succesfully sent', toast.success)
    } catch (e) {
      console.error('Error sending the code', e)
      displayToast('There was a error sending the verification', toast.error)
    }
  }
  const validateVerificationCode = async (code) => {
    try {
      await axios.post('/phone/verifyCode', {
        phoneNumber: phone,
        code: code,
        userId: user?.data?.id,
      })
      handleSucces()
      displayToast('Phone number was succesfully validated', toast.success, true)
      dispatch(setUser({ mobile: phone }))
    } catch (e) {
      console.error('Error verifying the code', e)
      displayToast('There was a error verifying the code', toast.error)
    }
  }

  return (
    <div className='h-full w-full'>
      {!phone ? (
        <PhoneForm
          sendVerificationCode={sendVerificationCode}
          handleBack={handleBack}
          phoneNumber={phoneNumber}
        />
      ) : (
        <CodeForm
          phone={phone}
          validateVerificationCode={validateVerificationCode}
          sendVerificationCode={sendVerificationCode}
        />
      )}
    </div>
  )
}

export default PhoneVerificationForm
