import { useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import StripeElementForm from './stripeElementForm'
import keys from '../../../server/config/keys'
import { useForm } from 'react-hook-form'
import Disclaimers from '../../my-teams/summary/disclaimers'
import Button from '@howdy/core/elements/Button'
import BillingInfoForm from '../billingInfoForm'
import useMobile from '../../../hooks/useMobile'

export default function StripeCardForm({
  setIsLoading,
  closeAction,
  onSuccessfullCompletion,
  showInvitationModal,
}) {
  const {
    register,
    getValues,
    trigger,
    control,
    formState: { errors },
  } = useForm({})
  const { isMobile } = useMobile()
  const [stripePromise] = useState(() => loadStripe(keys.stripePublicKey))
  const [step, setStep] = useState(0)
  const options = {
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css2?family=Urbanist:wght@500&display=swap',
      },
    ],
  }

  const handleSubmit = async () => {
    const isDataValid = await trigger(['companyLegalName', 'billingEmail', 'billingContactPhone'])
    if (!isDataValid) {
      return
    }
    setStep(1)
  }

  return (
    <>
      {step == 0 && (
        <>
          <BillingInfoForm register={register} errors={errors} control={control} />
          <Disclaimers invite={showInvitationModal} />
          <div className='mt-10 flex  flex-col-reverse items-center justify-center gap-5 lg:flex-row'>
            <Button
              fullWidth={isMobile}
              style={'secondary'}
              onClick={() => {
                closeAction()
              }}
            >
              Cancel
            </Button>
            <Button
              fullWidth={isMobile}
              onClick={() => {
                handleSubmit()
              }}
            >
              Next
            </Button>
          </div>
        </>
      )}
      {step == 1 && (
        <Elements stripe={stripePromise} options={options}>
          <StripeElementForm
            goBackMethod={() => {
              setStep(0)
            }}
            billingInfo={getValues()}
            setIsLoading={setIsLoading}
            onSuccessfullCompletion={onSuccessfullCompletion}
            showInvitationModal={showInvitationModal}
          />
        </Elements>
      )}
    </>
  )
}
