import { FC } from 'react'
import Button from '@howdy/core/elements/Button'

const DefaultFallbackComponent: FC = () => {
  return (
    <div className='flex h-screen w-full items-center justify-center bg-[#FEFAF6] text-center'>
      <div className='m-auto max-w-[1200px] p-12'>
        <div className='mb-4 flex items-center justify-center'>
          <svg
            width='56'
            height='56'
            viewBox='0 0 56 56'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle cx='28' cy='28' r='27' fill='white' stroke='#DCDCDC' />
            <path
              d='M27.9688 33.5938L24.3359 37.2266C24.0747 37.4888 23.7642 37.6968 23.4223 37.8388C23.0804 37.9807 22.7139 38.0538 22.3438 38.0538C21.9736 38.0538 21.6071 37.9807 21.2652 37.8388C20.9233 37.6968 20.6129 37.4888 20.3516 37.2266L17.7734 34.6484C17.5112 34.3871 17.3032 34.0767 17.1613 33.7348C17.0193 33.3929 16.9462 33.0264 16.9462 32.6563C16.9462 32.2861 17.0193 31.9196 17.1613 31.5777C17.3032 31.2358 17.5112 30.9254 17.7734 30.6641L21.4063 27.0312'
              stroke='#449386'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M19.0625 35.9375L15.3125 39.6875'
              stroke='#449386'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M39.6875 15.3125L35.9375 19.0625'
              stroke='#449386'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M29.375 29.375L26.5625 32.1875'
              stroke='#449386'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path d='M29.375 29.375L26.5625 32.1875' stroke='#449386' strokeWidth='0.125' />
            <path
              d='M25.625 25.625L22.8125 28.4375'
              stroke='#449386'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M33.5938 27.9687L37.2266 24.3359C37.4888 24.0746 37.6968 23.7641 37.8388 23.4222C37.9807 23.0804 38.0538 22.7139 38.0538 22.3437C38.0538 21.9735 37.9807 21.607 37.8388 21.2651C37.6968 20.9233 37.4888 20.6128 37.2266 20.3515L34.6484 17.7734C34.3871 17.5112 34.0767 17.3031 33.7348 17.1612C33.3929 17.0192 33.0264 16.9462 32.6563 16.9462C32.2861 16.9462 31.9196 17.0192 31.5777 17.1612C31.2358 17.3031 30.9254 17.5112 30.6641 17.7734L27.0312 21.4062'
              stroke='#449386'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M26.0938 20.4688L34.5312 28.9062'
              stroke='#449386'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M20.4688 26.0938L28.9062 34.5312'
              stroke='#449386'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>

        <div className='flex flex-col gap-10'>
          <div className='text-4xl font-bold'>
            Oops!
            <br />
            Something went wrong.
          </div>
          <div className='mb-4 text-lg'>Try reloading the page.</div>
        </div>
        <div className='mb-4 flex justify-center'>
          <Button onClick={() => window.location.reload()}>Reload</Button>
        </div>
      </div>
    </div>
  )
}

export default DefaultFallbackComponent
