import { useContext, createContext, useCallback, useState } from 'react'

type AuthCommonStateType = {
  isAuthenticated?: boolean
  logOut?: () => void
}

export interface AuthCommonContextType extends AuthCommonStateType {
  updateAuthCommonState: (status: AuthCommonStateType) => void
}

const AuthCommonContext = createContext<AuthCommonContextType>({
  updateAuthCommonState: () => null,
})

function AuthCommonProvider({ children }: { children: React.ReactNode }) {
  const [authState, setAuthState] = useState<AuthCommonStateType>({
    isAuthenticated: false,
    logOut: () => null,
  })

  const updateAuthCommonState = useCallback((status: AuthCommonStateType) => {
    setAuthState(status)
  }, [])

  return (
    <AuthCommonContext.Provider value={{ ...authState, updateAuthCommonState }}>
      {children}
    </AuthCommonContext.Provider>
  )
}

const useAuthCommon = () => useContext(AuthCommonContext)

export { AuthCommonProvider, useAuthCommon }
