import { ACTIONS } from '../contexts/recommendationsContext'

const setRecommendations = (recommendations) => {
  return {
    type: ACTIONS.setRecommendations,
    payload: recommendations,
  }
}

export default setRecommendations
