import cn from 'classnames'
import React from 'react'
import Step from './components/Step'

const DesktopStepper = ({
  steps,
  currentStep,
  className,
  stepsColor,
  joinColor = 'border-black',
  minHeight = 'min-h-[430px]',
  maxHeight = 'max-h-[600px]',
}) => {
  return (
    <div className={`${className} ${minHeight} ${maxHeight} h-full w-[300px]`}>
      <div className={cn('h-full pl-[36.5px]')}>
        <div className={cn('flex h-full flex-col justify-between border-l-[3px]', joinColor)}>
          {steps.map(({ title, icon }, index) => (
            <Step
              title={title}
              isSelected={index + 1 <= currentStep}
              icon={icon}
              color={stepsColor}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default DesktopStepper
