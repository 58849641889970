import React from 'react'

export default function GridFour({ width = 22, height = 23, color = 'currentColor' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.5606 4.33984H4.88051C4.51193 4.33984 4.21313 4.64176 4.21313 5.01419V17.8267C4.21313 18.1991 4.51193 18.501 4.88051 18.501H17.5606C17.9292 18.501 18.228 18.1991 18.228 17.8267V5.01419C18.228 4.64176 17.9292 4.33984 17.5606 4.33984Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.2205 4.33984V18.501'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.228 11.4199H4.21313'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
