import ReactGA from 'react-ga4'
import { useState, useEffect, useContext, createContext } from 'react'
import { useUser } from './userContext'
import { useRouter } from 'next/router'
import keys from '../server/config/keys'
import * as Sentry from '@sentry/nextjs'

const TrackingID = keys.gaId
const TrackingContext = createContext()

function TrackingProvider(props) {
  const { userState: user } = useUser()
  const router = useRouter()
  const [analytics, setAnalytics] = useState({
    isInitialized: false,
    hasUser: false,
    trackers: ['Main'],
  })

  const handleRouteChange = (url) => {
    ReactGA.set({ page: url }, analytics.trackers)
    // ReactGA.pageview(url, analytics.trackers, url);
    ReactGA.send('pageview')
  }

  const logEvent = ({ category = '', action = '', label = '' }) => {
    if (analytics.isInitialized) {
      ReactGA.event(
        {
          category,
          action,
          label,
        },
        analytics.trackers,
      )
    }
  }

  const setPageTitle = (title) => {
    if (analytics.isInitialized) {
      ReactGA.set({ page_title: title }, analytics.trackers)
      ReactGA.send('pageview')
    }
  }

  useEffect(() => {
    const { isInitialized, hasUser, trackers } = analytics
    const userId = user?.data?.id
    if (!isInitialized) {
      try {
        ReactGA.initialize(TrackingID, {
          // ...variousOptions,
          gaOptions: {
            userId: userId,
          },
        })
      } catch (error) {
        console.error(error)
        Sentry.captureException(error)
      }
      router.events.on('routeChangeComplete', handleRouteChange)
      setAnalytics((prev) => ({
        ...prev,
        isInitialized: true,
        hasUser: Boolean(userId),
      }))
    } else if (isInitialized && !hasUser) {
      ReactGA.set({ userId }, trackers)
      setAnalytics((prev) => ({
        ...prev,
        hasUser: Boolean(userId),
      }))
    }
    return () => {
      // clean up
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [user])

  return <TrackingContext.Provider value={{ logEvent, setPageTitle }} {...props} />
}

const useTracking = () => useContext(TrackingContext)

export { TrackingProvider, useTracking }
