import axios from 'axios'
import { useMemo } from 'react'
import { useRecommendationsContext } from '../contexts/recommendationsContext'
import setRecommendations from '../actions/setRecommendations'
import { useUser } from '../contexts/userContext'

export default function useRecommendations() {
  const {
    recommendationsState: {
      data: { recommendations },
    },
    dispatch,
  } = useRecommendationsContext()
  const { userState: user } = useUser()

  const newRecommendations = useMemo(
    () =>
      recommendations
        ?.filter(
          (recommendation) =>
            ['Sent', 'Viewed'].includes(recommendation.status) && !recommendation.archived,
        )
        ?.slice(0, 10) || [],
    [recommendations],
  )

  const getRecommendations = async ({
    defaultRecommendation = null,
    defaultCompany = null,
    sharingCode = null,
    forceReload = false,
  } = {}) => {
    if (recommendations?.length === 0 || forceReload) {
      if (user?.data?.company?.id || sharingCode) {
        const response = await axios.get(
          '/recommendation/' + (sharingCode ? `sharing/${sharingCode}` : user?.data?.company?.id),
        )
        const _recommendations = response.data.data
        const sortedRecommendations = _recommendations
          ?.sort((a, b) => {
            const dateA = new Date(a.createdAt)
            const dateB = new Date(b.createdAt)
            if (dateA < dateB) {
              return 1
            } else if (dateA > dateB) {
              return -1
            }
            return 0
          })
          ?.map((r) => {
            return {
              ...r,
              isNew:
                !r.events?.length &&
                !r.inviteEvents?.length &&
                ['Sent', 'Viewed'].includes(r.status),
            }
          })
        const archivedRecommendations =
          sortedRecommendations?.filter((recommendation) => recommendation.archived) || []
        const openRecommendations =
          sortedRecommendations?.filter(
            (recommendation) =>
              ['Sent', 'Viewed'].includes(recommendation.status) && !recommendation.archived,
          ) || []
        const acceptedRecommendations =
          sortedRecommendations?.filter(
            (recommendation) => recommendation.status == 'Accepted' && !recommendation.archived,
          ) || []
        const rejectedRecommendations =
          sortedRecommendations?.filter(
            (recommendation) => recommendation.status == 'Rejected' && !recommendation.archived,
          ) || []
        const checkingRecommendations =
          sortedRecommendations?.filter(
            (recommendation) => recommendation.status == 'Checking' && !recommendation.archived,
          ) || []
        dispatch(
          setRecommendations([
            ...openRecommendations,
            ...acceptedRecommendations,
            ...rejectedRecommendations,
            ...checkingRecommendations,
            ...archivedRecommendations,
          ]),
        )

        return response.data
        // case public route with recommendation in the url
      } else if (defaultRecommendation && defaultCompany) {
        const response = await axios.get('/recommendation', {
          params: {
            companyId: defaultCompany,
            engineerId: defaultRecommendation,
          },
        })

        const recommendation = response.data.data
        dispatch(setRecommendations([recommendation]))
      }
    } else {
      dispatch(setRecommendations(recommendations))
    }
  }

  const changeRecommendationStatus = async (recommendation, status, feed, sharingInfo) => {
    const feedback = feed || ''
    const response = await (!user?.data?.email && sharingInfo
      ? axios.put(`/recommendation/update/${recommendation.id}/from-sharing/${sharingInfo.code}`, {
          status,
          feedback,
        })
      : axios.put('/recommendation/update/' + recommendation.id, {
          status,
          feedback,
        }))

    if (response?.status == 200) {
      await getRecommendations({ sharingCode: sharingInfo?.code, forceReload: true })
    }
  }

  return { recommendations, getRecommendations, newRecommendations, changeRecommendationStatus }
}
