import axios from 'axios'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import setUser from '../../actions/setUser'
import Button from '@howdy/core/elements/Button'
import { useUser } from '../../contexts/userContext'
import SlidesModal from './slidesModal'
import stepContent from './stepContent'

export default function Slides({ display, closeAction, handleSuccess, flow, phoneNumber }) {
  const contentRef = useRef()
  const [step, setStep] = useState(0)
  const { userState: user, dispatch } = useUser()
  const steps = flow ? stepContent.filter((step) => step.flow == flow) : stepContent

  const getContent = () => {
    const { title, subtitle, mainContent } = steps[step]
    return (
      <>
        <h1
          className={classNames('text-howdy-primary-green', 'text-lg', 'lg:text-3xl', {
            'mb-4': title,
          })}
        >
          {title}
        </h1>
        <h2 className={classNames('font-bold lg:text-4xl', { 'mb-4': subtitle }, 'text-3xl')}>
          {subtitle}
        </h2>
        <div className={classNames('grow overflow-auto')} ref={contentRef}>
          {mainContent({
            handleBack,
            handleSuccess: handleSuccess || closeAction,
            phoneNumber,
          })}
        </div>
      </>
    )
  }
  const setUserRead = async () => {
    if (steps[step].markTutorialAsReaded) {
      await axios.put('/users', {
        id: user.data.id,
        tutorialRead: true,
      })
      dispatch(setUser({ tutorialRead: true }))
    }
  }
  useEffect(() => {
    setUserRead()
  }, [step])

  const handleContinue = () => {
    contentRef.current.scrollTop = 0
    if (step < steps.length - 1) {
      setStep(step + 1)
    } else {
      closeAction()
      setStep(0)
    }
  }

  const handleBack = () => {
    contentRef.current.scrollTop = 0
    if (step > 0) {
      setStep(step - 1)
    } else {
      closeAction()
    }
  }

  const [swipe, setSwipe] = useState(0)
  const swipeThreshold = 200

  const handlers = useSwipeable({
    onSwiping: (e) => setSwipe(Math.abs(e.deltaX)),
    onSwiped: () => setSwipe(0),
    onSwipedLeft: ({ deltaX }) => {
      if (Math.abs(deltaX) >= swipeThreshold) handleContinue()
    },
    onSwipedRight: ({ deltaX }) => {
      if (Math.abs(deltaX) >= swipeThreshold) handleBack()
    },
  })

  return (
    <SlidesModal
      display={display}
      closeAction={closeAction}
      showStepper={true}
      step={step}
      steps={steps}
    >
      <div
        className='relative flex h-full w-full flex-col text-black xsm:pt-4 xsm:text-xs'
        {...handlers}
      >
        {getContent()}
        {!steps[step].hideButtons && (
          <div className={classNames('block xsm:hidden', 'mt-6 flex items-center gap-8')}>
            {step != 0 && (
              <div>
                <Button style='tertiary' onClick={handleBack}>
                  Back
                </Button>
              </div>
            )}
            <div>
              <Button onClick={handleContinue}>Continue</Button>
            </div>
          </div>
        )}
        <div
          style={{ width: `${(swipe / swipeThreshold) * 100}%` }}
          className={classNames(
            'm-auto hidden h-[5px] max-w-full rounded-full bg-black transition-all duration-0 ease-linear',
            'xsm:block',
            {
              'xsm:bg-howdy-primary-green': swipe >= swipeThreshold,
            },
          )}
        />
        <div className='absolute -right-[120px] bottom-[0px] hidden h-[114px] w-[114px] rounded-full bg-howdy-primary-green md:block' />
      </div>
    </SlidesModal>
  )
}
