import { useEffect, useState } from 'react'

const mobileBreakpoint = 768

export default () => {
  const [isMobile, setIsMobile] = useState(null)

  useEffect(() => {
    setIsMobile(window.innerWidth < mobileBreakpoint)
    const resizeListener = () => {
      setIsMobile(window.innerWidth < mobileBreakpoint)
    }
    window.addEventListener('resize', resizeListener)

    return () => window.removeEventListener('resize', resizeListener)
  }, [])

  return {
    isMobile,
  }
}
