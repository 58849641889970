import React from 'react'

export default function CountryMexico({ width = 32, height = 32 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_163_1542)'>
        <mask
          style={{ maskType: 'luminance' }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width={32}
          height={32}
        >
          <path
            d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_163_1542)'>
          <path d='M9 0H22.9375L25 16L22.9375 32H9L7 16L9 0Z' fill='white' />
          <path d='M0 0H9V32H0V0Z' fill='#496E2D' />
          <path d='M23 0H32V32H23V0Z' fill='#D80027' />
          <path d='M16 17.3125V17.9375H16.75L17.375 16.5625L16 17.3125Z' fill='#FFDA44' />
          <path
            d='M10 15.125C10 16.7163 10.6321 18.2424 11.7574 19.3676C12.8826 20.4929 14.4087 21.125 16 21.125C17.5913 21.125 19.1174 20.4929 20.2426 19.3676C21.3679 18.2424 22 16.7163 22 15.125H21.3125C21.3125 16.534 20.7528 17.8852 19.7565 18.8815C18.7602 19.8778 17.409 20.4375 16 20.4375C14.591 20.4375 13.2398 19.8778 12.2435 18.8815C11.2472 17.8852 10.6875 16.534 10.6875 15.125H10ZM12.4375 16.1875L12.1875 16.3125C12.0625 16.4375 12.0625 16.6875 12.25 16.8125C13.1875 17.6875 14.375 18.1875 15.625 18.3125V19.375H16.375V18.3125C17.625 18.1875 18.8125 17.6875 19.75 16.8125C19.9375 16.6875 19.9375 16.4375 19.8125 16.3125C19.6875 16.1875 19.4375 16.1875 19.3125 16.3125C18.4135 17.1523 17.2302 17.6211 16 17.625C14.8125 17.625 13.625 17.125 12.6875 16.3125L12.4375 16.1875Z'
            fill='#496E2D'
          />
          <path
            d='M16 19.75C15.125 19.75 14.25 19.4375 13.5 18.9375L13.875 18.375C15.125 19.1875 16.875 19.1875 18.125 18.375L18.5625 18.9375C17.8125 19.4375 16.9375 19.75 16 19.75Z'
            fill='#338AF3'
          />
          <path
            d='M16 10.875C17.375 11.5625 16.75 12.9375 16.6875 13L16.5625 12.75C16.25 12.0625 15.4375 11.625 14.625 11.625V12.3125C15 12.3125 15.3125 12.625 15.3125 13C14.875 13.4375 14.75 14.0625 15.0625 14.625L15.3125 15.125L14.5 16.5625L16.3125 16.125L17.4375 17.25V16.5625L18.125 17.25L19.5625 16.5625L17.375 15.25L17.0625 13.9375L18.8125 14.9375C19.0625 15.625 19.5625 16.25 20.25 16.5625C20.8125 11.375 17.625 10.875 16.4375 10.875H16Z'
            fill='#751A46'
          />
          <path
            d='M13.875 16.9375C12.9375 16.9375 11.8125 16.1875 11.5 14.4375L12.1875 14.3125C12.4375 15.75 13.3125 16.25 13.875 16.25C14.0625 16.25 14.1875 16.1875 14.25 16.0625C14.25 15.9375 14.25 15.875 13.875 15.75C13.6875 15.6875 13.4375 15.625 13.25 15.4375C12.625 14.6875 13.5 13.9375 13.9375 13.5625C14 13.5 14.0625 13.4375 14 13.375C14 13.375 13.875 13.25 13.6875 13.25C13.25 13.25 12.9375 13 12.8125 12.5625C12.6875 12.1875 12.9375 11.75 13.3125 11.5L13.625 12.1875C13.5 12.1875 13.5 12.3125 13.5 12.4375C13.5 12.4375 13.5625 12.5625 13.6875 12.5625C14.125 12.5625 14.5625 12.8125 14.6875 13.125C14.75 13.3125 14.8125 13.6875 14.375 14.0625C13.9375 14.5 13.6875 14.8125 13.8125 15L14.125 15.0625C14.4375 15.1875 15 15.375 14.9375 16.125C14.875 16.625 14.4375 16.9375 13.875 16.9375Z'
            fill='#6DA544'
          />
          <path
            d='M14.625 11.625L13.875 12.3125V13L15 12.4375C15.1875 12.375 15.1875 12.125 15.0625 12L14.625 11.625Z'
            fill='#FFDA44'
          />
          <path
            d='M10.75 17.6875C11.0261 17.6875 11.25 17.4636 11.25 17.1875C11.25 16.9114 11.0261 16.6875 10.75 16.6875C10.4739 16.6875 10.25 16.9114 10.25 17.1875C10.25 17.4636 10.4739 17.6875 10.75 17.6875Z'
            fill='#FFDA44'
          />
          <path
            d='M11.8125 19.375C12.0886 19.375 12.3125 19.1511 12.3125 18.875C12.3125 18.5989 12.0886 18.375 11.8125 18.375C11.5364 18.375 11.3125 18.5989 11.3125 18.875C11.3125 19.1511 11.5364 19.375 11.8125 19.375Z'
            fill='#FFDA44'
          />
          <path
            d='M13.5 20.6875C13.7761 20.6875 14 20.4636 14 20.1875C14 19.9114 13.7761 19.6875 13.5 19.6875C13.2239 19.6875 13 19.9114 13 20.1875C13 20.4636 13.2239 20.6875 13.5 20.6875Z'
            fill='#FFDA44'
          />
          <path
            d='M18.5625 20.6875C18.8386 20.6875 19.0625 20.4636 19.0625 20.1875C19.0625 19.9114 18.8386 19.6875 18.5625 19.6875C18.2864 19.6875 18.0625 19.9114 18.0625 20.1875C18.0625 20.4636 18.2864 20.6875 18.5625 20.6875Z'
            fill='#FFDA44'
          />
          <path
            d='M20.25 19.375C20.5261 19.375 20.75 19.1511 20.75 18.875C20.75 18.5989 20.5261 18.375 20.25 18.375C19.9739 18.375 19.75 18.5989 19.75 18.875C19.75 19.1511 19.9739 19.375 20.25 19.375Z'
            fill='#FFDA44'
          />
          <path
            d='M21.3125 17.6875C21.5886 17.6875 21.8125 17.4636 21.8125 17.1875C21.8125 16.9114 21.5886 16.6875 21.3125 16.6875C21.0364 16.6875 20.8125 16.9114 20.8125 17.1875C20.8125 17.4636 21.0364 17.6875 21.3125 17.6875Z'
            fill='#FFDA44'
          />
          <path
            d='M16.375 18.6875H15.625C15.2453 18.6875 14.9375 18.9953 14.9375 19.375C14.9375 19.7547 15.2453 20.0625 15.625 20.0625H16.375C16.7547 20.0625 17.0625 19.7547 17.0625 19.375C17.0625 18.9953 16.7547 18.6875 16.375 18.6875Z'
            fill='#FF9811'
          />
        </g>
      </g>
      <defs>
        <clipPath>
          <rect width={32} height={32} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
