const EnvelopeSimple = ({ width = 31, height = 30, color = 'currentColor' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 31 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='EnvelopeSimple'>
      <path
        id='Vector'
        d='M4.25 6.5625H26.75V22.5C26.75 22.7486 26.6512 22.9871 26.4754 23.1629C26.2996 23.3387 26.0611 23.4375 25.8125 23.4375H5.1875C4.93886 23.4375 4.7004 23.3387 4.52459 23.1629C4.34877 22.9871 4.25 22.7486 4.25 22.5V6.5625Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector_2'
        d='M26.75 6.5625L15.5 16.875L4.25 6.5625'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
)

export default EnvelopeSimple
