import { createContext, useContext, useReducer } from 'react'
import produce from 'immer'

export const ACTIONS = {
  setRecommendations: 'SET_RECOMMENDATIONS',
  addProduct: 'ADD_PRODUCT',
  removeProduct: 'REMOVE_PRODUCT',
  deleteProduct: 'DELETE_PRODUCT',
  clearCart: 'CLEAR_CART',
  selectTeammate: 'SELECT_TEAMMATE',
}

export const RecommendationsInitialState = {
  data: {
    recommendations: [],
  },
}

export const RecommendationsContext = createContext(RecommendationsInitialState)

const reducer = (draft, action) => {
  switch (action.type) {
    case ACTIONS.setRecommendations:
      draft.data = {
        ...draft.data,
        recommendations: action.payload,
      }
      break
  }
}

export const recommendationsReducer = produce(reducer)

export const RecommendationsProvider = ({ children }) => {
  const [recommendationsState, dispatch] = useReducer(
    recommendationsReducer,
    RecommendationsInitialState,
  )

  return (
    <RecommendationsContext.Provider value={{ recommendationsState, dispatch }}>
      {children}
    </RecommendationsContext.Provider>
  )
}

export function useRecommendationsContext() {
  const context = useContext(RecommendationsContext)
  if (context === undefined) {
    throw new Error(
      `Your component should be a child of "RecommendationsProvider" to be able to use "useRecommendations" inside of it.
      Make sure to wrap your component or The "App" component with <RecommendationsProvider> ...</RecommendationsProvider>`,
    )
  }
  return context
}
