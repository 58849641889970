import React from 'react'

export default function CountryPlaceholder({ width = 32, height = 32 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_163_1588)'>
        <mask
          id='mask0_163_1588'
          style={{ maskType: 'luminance' }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width={32}
          height={32}
        >
          <path
            d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_163_1588)'>
          <path
            d='M2.47955e-05 32L2.158e-05 3.05176e-05L9.04377 2.96088e-05L16 1.50003L22.9563 2.82108e-05L32 2.7302e-05L32 32L22.9563 32L16 30.375L9.04377 32L2.47955e-05 32Z'
            fill='#959595'
          />
          <path
            d='M9.04386 32L9.04385 -3.05176e-05L22.9564 -3.19156e-05L22.9564 32L9.04386 32Z'
            fill='#C3C3C3'
          />
        </g>
      </g>
      <defs>
        <clipPath>
          <rect width={32} height={32} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
