import { useEffect, useCallback, useState } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import axios from 'axios'
import * as Sentry from '@sentry/nextjs'
import { useForm } from 'react-hook-form'
import Loader from '../../../components/loader'
import Button from '@howdy/core/elements/Button'
import Disclaimers from '../../my-teams/summary/disclaimers'
import { useUser } from '../../../contexts/userContext'
import setBillingInfo from '../../../actions/setBillingInfo'
import BillingInfoForm from '../billingInfoForm'
import { getPlaidToken, saveAchData } from '../../../utils/paymentInfoFunctions'
import useMobile from '../../../hooks/useMobile'

export default function PlaidLink({ closeAction, onSuccessfullCompletion, showInvitationModal }) {
  const {
    register,
    getValues,
    trigger,
    control,
    formState: { errors },
  } = useForm({})
  const [token, setToken] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { dispatch, userState } = useUser()
  const { data: user } = userState

  const { isMobile } = useMobile()
  async function createLinkToken() {
    setIsLoading(true)
    try {
      const link_token = await getPlaidToken(user.urlHash)
      setIsLoading(false)
      setToken(link_token)
    } catch (error) {
      setIsLoading(false)
      Sentry.captureException(error)
      console.error(error)
    }
  }

  useEffect(() => {
    createLinkToken()
  }, [])

  const onSuccess = useCallback(async (public_token, metadata) => {
    try {
      setIsLoading(true)
      const { companyLegalName, billingEmail, billingContactPhone } = getValues()
      const { zohoContactID, stripeCustomerID } = await saveAchData(
        public_token,
        metadata,
        companyLegalName,
        billingEmail,
        billingContactPhone,
      )
      const data = {
        stripeId: stripeCustomerID,
        zohoId: zohoContactID,
        paymentMethod: 'achDebit',
        companyLegalName,
        billingEmail,
        billingContactPhone,
      }
      await axios.put('/company', {
        id: user.company.id,
        ...data,
      })
      dispatch(setBillingInfo(data))
      onSuccessfullCompletion()
    } catch (error) {
      setIsLoading(false)
      Sentry.captureException(error)
      console.error(error)
    }
  }, [])

  const config = {
    token,
    onSuccess,
    //env: "sandbox"
    // onExit
    // onEvent
  }

  const { open, ready, error } = usePlaidLink(config)

  const handleConnectBanckAcount = async () => {
    const isDataValid = await trigger(['companyLegalName', 'billingEmail', 'billingContactPhone'])
    if (!isDataValid) {
      return
    }
    setIsLoading(true)
    open(setIsLoading(false))
  }

  return (
    <div className='text-as-black-blue flex h-full flex-col items-center justify-between'>
      {isLoading && (
        <div className='relative flex h-36 w-full flex-col items-center justify-center'>
          <Loader loading={isLoading} />
        </div>
      )}

      {!isLoading && (
        <>
          <BillingInfoForm register={register} errors={errors} control={control} />
          <Disclaimers invite={showInvitationModal} />
          <div className='mt-10 flex w-full flex-col-reverse items-center justify-center gap-5 lg:flex-row'>
            <Button
              fullWidth={isMobile}
              style={'secondary'}
              onClick={() => {
                closeAction()
              }}
            >
              Cancel
            </Button>
            <Button
              fullWidth={isMobile}
              disabled={!ready}
              onClick={() => {
                handleConnectBanckAcount()
              }}
            >
              Connect Bank Account
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
