import Modal from '../../../components/modal'
import { useState } from 'react'
import PlaidLink from '../../paymentInfoForms/plaidLink'
import StripeCardForm from '../../paymentInfoForms/stripeCardForm'
import Circle from '@howdy/core/elements/Circle'
import CreditCard from '@howdy/core/elements/Icons/CreditCard'

export default function BillingInfoModal({
  display,
  onSuccessfullCompletion,
  closeAction,
  showInvitationModal,
}) {
  const [paymentMethod, setPaymentMethod] = useState('ACH')

  const paymetnMethodChange = (e) => {
    setPaymentMethod(e.target.value)
  }

  return (
    <Modal display={display} size='lg'>
      <div className='max-h-[80vh] w-full lg:w-[512px]'>
        <div className='flex w-full justify-end p-4'>
          <button onClick={closeAction}>
            <img src='/close.svg' width={15} height={24} />
          </button>
        </div>
        <div className='flex flex-col items-center p-4 pt-0 lg:p-6 '>
          <Circle bgColor='white' borderColor='#F2F2F2' className='text-howdy-primary-green'>
            <CreditCard />
          </Circle>
          <h1 className='mt-3 text-xl font-bold text-howdy-black'>
            Billing and payment information
          </h1>
          <div className='mt-4'>
            <h2 className='hidden text-lg font-bold lg:block'>Payment Method</h2>
            <div className='mt-4 flex items-center gap-3' onChange={paymetnMethodChange}>
              <label className='radioContainer w-full'>
                ACH Debit
                <input
                  name='paymentMethod'
                  type='radio'
                  id='ACH'
                  defaultChecked={true}
                  value={'ACH'}
                  checked={paymentMethod == 'ACH'}
                />
                <span class='checkmark'></span>
              </label>
              <label className='radioContainer w-full'>
                Credit Card
                <input
                  name='paymentMethod'
                  type='radio'
                  id='CREDIT'
                  defaultChecked={true}
                  value={'CREDIT'}
                  checked={paymentMethod == 'CREDIT'}
                />
                <span class='checkmark'></span>
              </label>
            </div>
            <div>
              {paymentMethod === 'ACH' && (
                <PlaidLink
                  closeAction={closeAction}
                  onSuccessfullCompletion={onSuccessfullCompletion}
                  showInvitationModal={showInvitationModal}
                />
              )}
              {paymentMethod === 'CREDIT' && (
                <StripeCardForm
                  closeAction={closeAction}
                  onSuccessfullCompletion={onSuccessfullCompletion}
                  showInvitationModal={showInvitationModal}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
