import React, { ReactNode, useEffect } from 'react'
import LayoutDesktop from './LayoutDesktop'
import LayoutMobile from './LayoutMobile'
import { Section } from '../NavigationSideBar/types'
import { useUser } from '../../../../contexts/userContext'
import useRecommendations from '../../../../hooks/useRecomendations'
import useMobile from '../../../../hooks/useMobile'

const Layout = ({
  children,
  menuSections,
  hideMenu,
  hideFooter,
}: {
  children: ReactNode
  menuSections: Section[]
  hideMenu?: boolean
  hideFooter?: boolean
}) => {
  const { isMobile } = useMobile()
  const { recommendations, getRecommendations } = useRecommendations()
  const { userState: user } = useUser()

  useEffect(() => {
    getRecommendations()
  }, [user])

  if (isMobile === null) {
    return <></>
  }

  if (hideMenu) {
    return <>{children}</>
  }

  return isMobile ? (
    <LayoutMobile
      menuSections={menuSections}
      news={{ 'My Candidates': recommendations.some((r) => r.isNew) }}
      hideFooter={hideFooter}
    >
      {children}
    </LayoutMobile>
  ) : (
    <LayoutDesktop
      menuSections={menuSections}
      news={{ 'My Candidates': recommendations.some((r) => r.isNew) }}
      hideFooter={hideFooter}
    >
      {children}
    </LayoutDesktop>
  )
}

export default Layout
