import React from 'react'

export default function VideoCamera({ width = 30, height = 30 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 31 30'
      fill='none'
    >
      <path
        d='M3.3125 7.03125H18.3125C19.3071 7.03125 20.2609 7.42634 20.9641 8.1296C21.6674 8.83286 22.0625 9.78669 22.0625 10.7812V22.0312C22.0625 22.2799 21.9637 22.5183 21.7879 22.6942C21.6121 22.87 21.3736 22.9688 21.125 22.9688H6.125C5.13044 22.9688 4.17661 22.5737 3.47335 21.8704C2.77009 21.1671 2.375 20.2133 2.375 19.2188V7.96875C2.375 7.72011 2.47377 7.48165 2.64959 7.30584C2.8254 7.13002 3.06386 7.03125 3.3125 7.03125V7.03125Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.0625 13.125L28.625 9.375V20.625L22.0625 16.875'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
