import { useState } from 'react'
import Button from '@howdy/core/elements/Button'
import { useUser } from '../../contexts/userContext'
import CollaboratorModal from '../collaboratorModal'
import BillingInfoModal from '../my-teams/billingInfo/billingInfoModal'
import Circle from '@howdy/core/elements/Circle'
import CloseIcon from '@howdy/core/elements/Icons/CloseIcon'
import PencilEdit from '@howdy/core/elements/Icons/PencilEdit'

export default function NoBillingInfoWarning() {
  const [showBillingInfoModal, setShowBillingInfoModal] = useState(false)
  const [showInvitationModal, setShowInvitationModal] = useState(false)
  const { userState: user } = useUser()

  const showInvitation = () => {
    setShowBillingInfoModal(false)
    setShowInvitationModal(true)
  }
  return (
    <>
      {user.data?.company &&
        user.data.company?.orders.length !== 0 &&
        !user.data?.company?.zohoId &&
        !user.data?.company?.stripeId && (
          <div className='sticky right-0 top-0 z-20 flex w-full flex-col items-center justify-between gap-3 bg-as-warning-red px-4 py-5 lg:mx-0 lg:mt-0 lg:mt-0 lg:flex-row lg:items-center lg:px-12 lg:py-2.5 lg:pl-4'>
            <div className='flex flex-col items-center gap-4 lg:flex-row'>
              <Circle
                size={35}
                bgColor='white'
                borderColor='white'
                className='p-1.5 !text-as-warning-red'
              >
                <CloseIcon />
              </Circle>
              <p className='text-center text-lg font-bold text-white'>
                Fill out your billing information so we can start searching for your team
              </p>
            </div>
            <Button
              style={'secondary'}
              onClick={() => {
                setShowBillingInfoModal(true)
              }}
            >
              <PencilEdit /> Fill your billing info
            </Button>
          </div>
        )}
      <BillingInfoModal
        display={showBillingInfoModal}
        closeAction={() => {
          setShowBillingInfoModal(false)
        }}
        onSuccessfullCompletion={() => {
          setShowBillingInfoModal(false)
        }}
        showInvitationModal={showInvitation}
      />
      <CollaboratorModal
        display={showInvitationModal}
        closeAction={() => {
          setShowInvitationModal(false)
        }}
        backAction={() => {
          setShowInvitationModal(false)
          setShowBillingInfoModal(true)
        }}
        isFillPaymentInvitation
      />
    </>
  )
}
