import React from 'react'
import cn from 'classnames'

const MobileStepper = ({
  numberOfSteps,
  currentStep,
  className = '',
  color = 'howdy-primary-green',
  onClickStep,
}) => {
  const bgColor = 'bg-' + color
  return (
    <div className={className}>
      <div className={cn('align-center flex gap-[12px]')}>
        {new Array(numberOfSteps).fill(0).map((_, index) => {
          const isSelected = index === currentStep

          return (
            <div
              className={cn(
                'h-[10px] w-[10px] rounded-full transition-all duration-300 ease-out',
                `${isSelected ? bgColor : 'bg-black'} ${onClickStep ? 'cursor-pointer' : ''}`,
              )}
              onClick={() => (onClickStep ? onClickStep(index) : null)}
            />
          )
        })}
      </div>
    </div>
  )
}

export default MobileStepper
