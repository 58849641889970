import React from 'react'

export default function PencilEdit({ color = 'currentColor' }) {
  return (
    <svg width='22' height='23' viewBox='0 0 22 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.55101 18.839H4.54677C4.36977 18.839 4.20002 18.7679 4.07487 18.6415C3.94971 18.515 3.8794 18.3435 3.8794 18.1646V14.3967C3.87909 14.3092 3.89589 14.2224 3.92881 14.1414C3.96173 14.0604 4.01014 13.9868 4.07127 13.9247L14.0819 3.80957C14.144 3.74586 14.218 3.69526 14.2996 3.66072C14.3812 3.62618 14.4689 3.6084 14.5574 3.6084C14.6459 3.6084 14.7335 3.62618 14.8151 3.66072C14.8967 3.69526 14.9708 3.74586 15.0329 3.80957L18.7535 7.56903C18.8165 7.63178 18.8666 7.70657 18.9008 7.78906C18.935 7.87154 18.9526 7.96007 18.9526 8.0495C18.9526 8.13892 18.935 8.22745 18.9008 8.30994C18.8666 8.39243 18.8165 8.46722 18.7535 8.52997L8.55101 18.839Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.8877 6.02637L16.5593 10.7468'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.5614 18.8394H8.5508L3.9209 14.1611'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.2238 8.38672L6.21533 16.4788'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
