import { createContext, useContext, useEffect, useReducer } from 'react'
import produce from 'immer'
import { useLocalStorage } from '@howdy/core/lib/utils'

export const ACTIONS = {
  setProducts: 'SET_PRODUCTS',
  addProduct: 'ADD_PRODUCT',
  removeProduct: 'REMOVE_PRODUCT',
  deleteProduct: 'DELETE_PRODUCT',
  clearCart: 'CLEAR_CART',
  selectTeammate: 'SELECT_TEAMMATE',
}

export const StoreCartContext = createContext()

export const storeCartInitialState = {
  data: {
    teammate: null,
    products: [],
  },
}

const reducer = (draft, action) => {
  switch (action.type) {
    case ACTIONS.setProducts:
      draft.data = {
        ...draft.data,
        products: action.payload,
      }
      break
    case ACTIONS.addProduct:
      draft.data = {
        ...draft.data,
        products:
          draft.data.products.findIndex((product) => product.id == action.payload.id) != -1
            ? draft.data.products.map((product) => {
                if (product.id != action.payload.id) {
                  return product
                }
                return {
                  ...product,
                  amount: product.amount + 1,
                }
              })
            : [...draft.data.products, { ...action.payload, amount: 1 }],
      }
      break
    case ACTIONS.removeProduct:
      draft.data = {
        ...draft.data,
        products: draft.data.products.map((product) => {
          if (product.id != action.payload.id) {
            return product
          }
          return {
            ...product,
            amount: product.amount - 1,
          }
        }),
      }
      break
    case ACTIONS.deleteProduct:
      draft.data = {
        ...draft.data,
        products: draft.data.products.filter((product) => product.id != action.payload.id),
      }
      break
    case ACTIONS.clearCart:
      draft.data = {
        ...draft.data,
        teammate: null,
        products: [],
      }
      break
    case ACTIONS.selectTeammate:
      draft.data = {
        ...draft.data,
        teammate: action.payload,
      }
      break
  }
}

export const storeCartReducer = produce(reducer)

export const StoreCartProvider = ({ children }) => {
  const [shoppingCart, setShoppingCart] = useLocalStorage(
    '@howdy/shoppingCart',
    storeCartInitialState,
  )
  const [storeCartState, dispatch] = useReducer(storeCartReducer, shoppingCart)

  useEffect(() => {
    setShoppingCart(storeCartState)
  }, [storeCartState])

  return (
    <StoreCartContext.Provider value={{ storeCartState, dispatch }}>
      {children}
    </StoreCartContext.Provider>
  )
}

export function useStoreCart() {
  const context = useContext(StoreCartContext)
  if (context === undefined) {
    throw new Error(
      `Your component should be a child of "StoreCartProvider" to be able to use "useStoreCart" inside of it.
      Make sure to wrap your component or The "App" component with <StoreCartProvider> ...</StoreCartProvider>`,
    )
  }
  return context
}
