import React, { useState } from 'react'
import { Section } from './types'
import SectionElement from './SectionElement'

const SectionsBlock = ({
  sections,
  isNavExpanded,
  closeNavBar,
  news,
}: {
  sections: Section[]
  isNavExpanded: boolean
  closeNavBar: Function
  news: any
}) => {
  const [activeSection, setActiveSection] = useState('')
  const [selectedSubItem, setSelectedSubItem] = useState('')
  return (
    <ul className='p-[-3px]'>
      {sections?.map((section, index) => (
        <SectionElement
          key={`section-${index}`}
          section={section}
          isNavExpanded={isNavExpanded}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          selectedSubItem={selectedSubItem}
          setSelectedSubItem={setSelectedSubItem}
          closeNavBar={closeNavBar}
          news={news}
        />
      ))}
    </ul>
  )
}

export default SectionsBlock
