import React, { ReactNode } from 'react'
import NavigationSideBarMobile from '@howdy/core/elements/NavigationSideBar/NavigationSideBarMobile'
import ErrorBoundary from '@howdy/core/elements/ErrorBoundary'
import { Section } from '../NavigationSideBar/types'
import Footer from '../../../../components/Footer'

const LayoutMobile = ({
  children,
  menuSections,
  news,
  hideFooter,
}: {
  children: ReactNode
  menuSections: Section[]
  news: any
  hideFooter?: boolean
}) => {
  return (
    <div className='relative'>
      <div className='min-h-screen overflow-auto'>
        <NavigationSideBarMobile menuSections={menuSections} news={news} />
        <div className=' bg-howdy-dark-wheat pb-[150px]'>
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
      </div>
      {!hideFooter && <Footer />}
    </div>
  )
}

export default LayoutMobile
