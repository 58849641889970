import React, { PropsWithChildren } from 'react'
import clsx from 'clsx'

function Circle({
  children,
  className,
  size = 55,
  bgColor = 'white',
  borderColor = '#DCDCDC',
}: PropsWithChildren<{
  size?: number
  className?: string
  bgColor?: string
  borderColor?: string
}>) {
  return (
    <div
      className='relative inline-block'
      style={{
        height: size,
        width: size,
      }}
    >
      <div
        className={clsx(
          'rounded-full border',
          'z-10 flex h-full w-full items-center justify-center text-howdy-primary-green',
          className,
        )}
        style={{ borderColor: borderColor, backgroundColor: bgColor }}
      >
        {children}
      </div>
    </div>
  )
}

export default Circle
