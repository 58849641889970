import React from 'react'

export default function Warning() {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15 12.1875V16.875'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.3828 4.68749L3.07032 22.5C2.90603 22.7845 2.8194 23.1073 2.81911 23.4358C2.81882 23.7644 2.90487 24.0873 3.06866 24.3721C3.23244 24.657 3.4682 24.8938 3.75232 25.0588C4.03644 25.2239 4.35893 25.3113 4.6875 25.3125H25.3125C25.6411 25.3113 25.9636 25.2239 26.2477 25.0588C26.5318 24.8938 26.7676 24.657 26.9314 24.3721C27.0951 24.0873 27.1812 23.7644 27.1809 23.4358C27.1806 23.1073 27.094 22.7845 26.9297 22.5L16.6172 4.68749C16.4541 4.40257 16.2187 4.16579 15.9347 4.00111C15.6507 3.83642 15.3283 3.74969 15 3.74969C14.6717 3.74969 14.3493 3.83642 14.0653 4.00111C13.7813 4.16579 13.5459 4.40257 13.3828 4.68749V4.68749Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15 22.2656C15.6472 22.2656 16.1719 21.741 16.1719 21.0938C16.1719 20.4465 15.6472 19.9219 15 19.9219C14.3528 19.9219 13.8281 20.4465 13.8281 21.0938C13.8281 21.741 14.3528 22.2656 15 22.2656Z'
        fill='currentColor'
      />
    </svg>
  )
}
