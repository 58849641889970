import React, { useState } from 'react'
import { Props } from 'react-modal'
import { Form } from 'react-final-form'

import Base from './Base'
import Circle from '../Circle'
import Button from '../Button'
import EnvelopeSimple from '../Icons/EnvelopeSimple'
import InputField from '@howdy/core/elements/FormElements/InputField'
import { validateEmail } from '@howdy/core/lib/utils'

const RequestAccessModal = ({
  isOpen,
  onRequestOk,
  onRequestClose,
}: Props & { onRequestOk: () => void }) => {
  return (
    <Base isOpen={isOpen} onRequestClose={onRequestClose}>
      <Form
        mutators={{
          clearEmail: (args, state, utils) => {
            utils.changeValue(state, 'email', () => '')
          },
        }}
        initialValues={{ email: '' }}
        onSubmit={async (values: FormData, form) => {
          onRequestOk(values.email)

          form.mutators.clearEmail()
        }}
        render={({ handleSubmit, values }) => {
          return (
            <div className='flex flex-col items-center md:w-[500px]'>
              <Circle>
                <EnvelopeSimple />
              </Circle>
              <h1 className='mb-4 mt-4 text-center text-xl font-bold text-[#1B1B1B]'>
                You need permission to access this page
              </h1>
              <p className='mb-4 text-center'>
                You have been invited as guest. To access other areas it is necessary to request
                permission to the account owner.
              </p>
              <InputField name='email' placeholder={'Add email'} />
              <div className='flex w-full flex-col gap-2 md:w-auto md:flex-row'>
                <div className='order-1 md:order-2'>
                  <Button
                    onClick={handleSubmit}
                    disabled={!values.email || !validateEmail(values.email)}
                    className='w-full md:w-auto'
                  >
                    Request access
                  </Button>
                </div>
                <div className='order-2 md:order-1'>
                  <Button style={'secondary'} onClick={onRequestClose} className='w-full md:w-auto'>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          )
        }}
      />
    </Base>
  )
}

export default RequestAccessModal
