import { useState, useEffect, useRef, createRef } from 'react'
import Button from '@howdy/core/elements/Button'
import OtpInput from 'react18-input-otp'

const CodeForm = ({ phone, validateVerificationCode, sendVerificationCode }) => {
  const inputRef = useRef([])
  if (inputRef.current.length !== 6) {
    // add or remove refs
    inputRef.current = Array(6)
      .fill()
      .map((_, i) => createRef())
  }
  const [code, setCode] = useState('')
  const [errorCode, setErrorCode] = useState(false)
  const [timer, setTimer] = useState(30)
  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        const newTimer = timer - 1
        setTimer(newTimer)
      }, 1000)
    }
  }, [timer])

  const onSubmit = async () => {
    if (code.length === 6) {
      validateVerificationCode(code)
    } else {
      setErrorCode(true)
    }
  }

  const handleResendCode = () => {
    sendVerificationCode(phone)
    setTimer(30)
  }

  return (
    <div className='h-full'>
      <div className='flex h-full flex-col justify-between'>
        <div>
          <h1 className='text-lg text-howdy-primary-green md:text-3xl'>VERIFY INFO</h1>
          <h1 className='mb-4 text-2xl font-bold md:text-5xl'>Enter the code</h1>
          <h1 className='mt-2 text-base font-normal'>We sent you a code verification to {phone}</h1>
          <h1 className='mt-6 text-sm font-normal'>Code verification</h1>
          <OtpInput
            value={code}
            onChange={setCode}
            numInputs={6}
            separator={<div className='w-[10px]' />}
            inputStyle={
              '!w-[40px] h-[45px] text-center rounded-lg focus:ring-0 focus:border-howdy-primary-green placeholder:text-lg'
            }
            isInputNum={true}
            placeholder='······'
          />
          {errorCode && <p>Please enter a valid 6 digit code</p>}
        </div>
        <div className='xsm: mb-4 mr-6 mt-10 flex flex-row gap-8 xsm:mt-4 xsm:flex-row-reverse xsm:justify-center'>
          <Button disabled={timer > 0} style={'tertiary'} onClick={handleResendCode}>
            {!timer ? 'Resend Code' : `Resend Code in ${timer}s`}
          </Button>
          <Button onClick={onSubmit}>Send</Button>
        </div>
      </div>
    </div>
  )
}

export default CodeForm
