import React from 'react'

export default function Calculator({ width = 22, height = 23 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.2248 6.02539H7.21631V10.0714H15.2248V6.02539Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.8943 18.838V4.00247C17.8943 3.63004 17.5955 3.32812 17.2269 3.32812L5.2142 3.32813C4.84562 3.32813 4.54683 3.63004 4.54683 4.00247L4.54683 18.838C4.54683 19.2104 4.84562 19.5123 5.2142 19.5123H17.2269C17.5955 19.5123 17.8943 19.2104 17.8943 18.838Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.65525 13.1066C8.65525 13.5382 8.30913 13.887 7.88353 13.887C7.45794 13.887 7.11182 13.5382 7.11182 13.1066C7.11182 12.675 7.45794 12.3262 7.88353 12.3262C8.30913 12.3262 8.65525 12.675 8.65525 13.1066Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='0.125'
      />
      <path
        d='M11.9922 13.1066C11.9922 13.5382 11.646 13.887 11.2204 13.887C10.7949 13.887 10.4487 13.5382 10.4487 13.1066C10.4487 12.675 10.7949 12.3262 11.2204 12.3262C11.646 12.3262 11.9922 12.675 11.9922 13.1066Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='0.125'
      />
      <path
        d='M15.3291 13.1066C15.3291 13.5382 14.983 13.887 14.5574 13.887C14.1318 13.887 13.7856 13.5382 13.7856 13.1066C13.7856 12.675 14.1318 12.3262 14.5574 12.3262C14.983 12.3262 15.3291 12.675 15.3291 13.1066Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='0.125'
      />
      <path
        d='M7.88353 17.3206C8.34426 17.3206 8.71775 16.9432 8.71775 16.4777C8.71775 16.0122 8.34426 15.6348 7.88353 15.6348C7.42281 15.6348 7.04932 16.0122 7.04932 16.4777C7.04932 16.9432 7.42281 17.3206 7.88353 17.3206Z'
        fill='currentColor'
      />
      <path
        d='M11.2204 17.3206C11.6812 17.3206 12.0547 16.9432 12.0547 16.4777C12.0547 16.0122 11.6812 15.6348 11.2204 15.6348C10.7597 15.6348 10.3862 16.0122 10.3862 16.4777C10.3862 16.9432 10.7597 17.3206 11.2204 17.3206Z'
        fill='currentColor'
      />
      <path
        d='M14.5574 17.3206C15.0181 17.3206 15.3916 16.9432 15.3916 16.4777C15.3916 16.0122 15.0181 15.6348 14.5574 15.6348C14.0966 15.6348 13.7231 16.0122 13.7231 16.4777C13.7231 16.9432 14.0966 17.3206 14.5574 17.3206Z'
        fill='currentColor'
      />
    </svg>
  )
}
